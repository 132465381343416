import { Field } from 'formik'
import { HiddenPropsCommon } from './index'

export type FormikHiddenProps = HiddenPropsCommon

export const FormikHidden = ({ name, ...props }: FormikHiddenProps) => <Field type="hidden" name={name} {...props} />

FormikHidden.displayName = 'Form.Text.FormikHidden'

export default FormikHidden

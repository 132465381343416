export type HelpTextProps = {
  className?: string
  children: React.ReactNode
}

export const HelpText = ({ className, children }: HelpTextProps) => (
  <>
    <small className={`text-gray-500 text-sm whitespace-pre-wrap ${className}`}>{children}</small>
  </>
)

HelpText.displayName = 'Form.HelpText'

export default HelpText

import * as UI from '@/ui'

export type ButtonProps = Omit<UI.ButtonProps, 'type'>

export const Button = ({ children, ...props }: ButtonProps) => {
  return (
    <UI.Button type="button" ui-component="Form.Button" {...(props as UI.ButtonProps)}>
      {children}
    </UI.Button>
  )
}

Button.displayName = 'Form.Button'

export default Button

/* eslint-disable no-restricted-syntax */
import * as chrono from 'chrono-node'
import { ChangeEvent, useEffect, useState } from 'react'
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid'
import { Field, useField, useFormikContext } from 'formik'
import { range } from 'lodash-es'
import useUi from '../../use-ui'
import { importDate, toStringDate } from '../../../../helpers/date'
import { DatePickerPropsCommon, boxSizes } from '.'
import * as UI from '@/ui'

export type FormikDateTimePickerProps = DatePickerPropsCommon

const FormikDateTimePicker = ({
  name,
  onKeyUp,
  errorMessage,
  boxSize,
  placeholder,
  ...props
}: FormikDateTimePickerProps) => {
  const { isSubmitting } = useFormikContext()
  const [fieldProps, meta, { setTouched, setValue }] = useField({ name })

  const [dateString, setDateString] = useState<string>(getCurrentDate())
  const [hourString, setHourString] = useState<string>(getCurrentHour())
  const [minuteString, setMinuteString] = useState<string>(getCurrentMinute())

  useEffect(() => {
    if (isSubmitting) {
      setTouched(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting])

  useEffect(() => {
    const date = chrono.en.GB.parseDate(dateString, new Date())
    if (date) {
      setValue(createDateString(date, hourString, minuteString))
    } else {
      setValue(invalidDate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateString, hourString, minuteString])

  errorMessage = meta.touched && meta.error ? meta.error : errorMessage

  const { className } = useUi({
    styles: {
      boxSize: { options: boxSizes, selected: boxSize },
    },
    name: 'Form.DatePicker',
    className: `${props.className} ${
      errorMessage
        ? 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 placeholder-red-300'
        : meta.touched &&
          'focus:ring-green-500 focus:border-green-500 border-green-500 text-green-900 placeholder-green-300'
    } focus:ring-selphBlue-300 focus:border-selphBlue-300`,
  })

  if (!fieldProps) {
    return <p>Loading...</p>
  }

  const keyup = (e: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyUp && onKeyUp(e)
  }

  return (
    <>
      <div className="mt-1 rounded-md z-1">
        <div className="md:flex">
          <div className="relative">
            <label className="text-[0.825rem] font-light">Date</label>

            <Field
              name={name}
              type="text"
              placeholder={placeholder}
              value={dateString}
              onKeyUp={keyup}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setDateString(e.target.value)}
              className={className}
            />

            {errorMessage && (
              <ExclamationCircleIcon
                className="absolute right-3 top-8 h-5 w-5 text-red-500 pointer-events-none"
                aria-hidden="true"
              />
            )}

            {meta.touched && !errorMessage && (
              <CheckCircleIcon
                className="absolute right-3 top-8 h-5 w-5 text-green-500 pointer-events-none"
                aria-hidden="true"
              />
            )}
          </div>

          <div className="md:ml-4">
            <label className="text-[0.825rem] font-light">Time</label>

            <div className="flex">
              <select
                name="hourString"
                className={className}
                value={hourString}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setHourString(e.target.value)}
              >
                {range(0, 24).map((hour) => (
                  <option key={hour} value={stringifyTime(hour)}>
                    {stringifyTime(hour)}
                  </option>
                ))}
              </select>

              <span className="text-[1.25rem]">&nbsp;:&nbsp;</span>

              <select
                name="minuteString"
                className={className}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setMinuteString(e.target.value)}
                value={minuteString}
              >
                {range(0, 60, 15).map((minute) => (
                  <option key={minute} value={stringifyTime(minute)}>
                    {stringifyTime(minute)}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {fieldProps.value && (
          <p className="mt-1 text-sm text-green-600">
            {chrono.en.GB.parseDate(dateString)?.toLocaleDateString(undefined, {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </p>
        )}
      </div>

      {errorMessage && <UI.Form.Error>{errorMessage}</UI.Form.Error>}
    </>
  )
}

FormikDateTimePicker.displayName = 'Form.DatePicker.FormikDateTimePicker'

export default FormikDateTimePicker

const stringifyTime = (value: number) => value.toString().padStart(2, '0')

const today = importDate(new Date())
const invalidDate = new Date('')

const getCurrentDate = (): string => toStringDate(today)

const getCurrentHour = (): string => stringifyTime(today.hour)

const getCurrentMinute = (): string => {
  switch (true) {
    case today.minute < 15:
      return '00'
    case today.minute < 30:
      return '15'
    case today.minute < 45:
      return '30'
    case today.minute < 60:
      return '45'
    default:
      return '00'
  }
}

const createDateString = (date: Date, hour: string, minute: string): string => {
  const hourValue = parseInt(hour)
  const minuteValue = parseInt(minute)
  date.setHours(hourValue, minuteValue, 0, 0)

  return date.toISOString()
}

import * as chrono from 'chrono-node'
import { Fragment } from 'react'
import { object, string, array, date, mixed, number, StringSchema, ArraySchema, ObjectSchema, DateSchema } from 'yup'
const yup = { object, string, array, date, mixed, number }
import { pick, intersection } from 'lodash-es'
import { AnyObject } from 'yup/lib/object'
import BaseSchema, { AnySchema } from 'yup/lib/schema'
import { FormikValues } from 'formik/dist/types'
import { DateTime } from 'luxon'
import List from '../list'
import { DateStamp, fromIsoString, fromStringDate, importDate, now, toStringDate } from '../../../helpers/date'
import { QuestionDependsValue } from '../quiz/question'

export type FormValidationCriteria =
  | 'alphanumericExtra'
  | 'confirmationCode'
  | 'emailAddress'
  | 'password'
  | 'passwordSignUp'
  | 'kitId'
  | 'trackingNumber'
  | 'orderId'
  | 'sku'
  | 'zendeskTicketNumber'
  | 'customerId'
  | 'discountCode'

export const validationCriterias = {
  alphanumericExtra: {
    regex: /^[0-9a-zA-Z -]*$/,
    message: `can only include letters, number, spaces and hyphens`,
  },
  confirmationCode: {
    regex: /^[0-9]{6}$/,
    message: `can only include 6 digits`,
  },
  emailAddress: {
    regex:
      // eslint-disable-next-line no-control-regex
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
    message: `must be a valid email address`,
  },
  password: {
    regex: /(?=(.*[0-9]))(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/,
    message: (
      <Fragment>
        must be a secure password. <br />
        <List gap="small">
          <li>(8) characters in Length</li>
          <li>(1) Uppercase character</li>
          <li>(1) Lowercase character</li>
          <li>(1) Number</li>
          <li>(1) Symbol / Special Character</li>
        </List>
      </Fragment>
    ),
  },
  passwordSignUp: {
    regex: /(?=(.*[0-9]))(?=(.*[^A-Za-z0-9]))(?=.*[a-z])(?=(.*[A-Z])).{8,99}/,
    message: (
      <Fragment>
        must be a secure password. <br />
        <List gap="small">
          <li>(8 to 99) characters in Length</li>
          <li>(1) Uppercase character</li>
          <li>(1) Lowercase character</li>
          <li>(1) Number</li>
          <li>(1) Symbol / Special Character</li>
        </List>
      </Fragment>
    ),
  },

  sku: {
    regex: /^[a-zA-z0-9-]{2,}$/,
    message: `must be a valid Product Sku`,
  },
  kitId: {
    regex: /^[a-zA-z0-9]{4}-?[a-zA-z0-9]{4}$/,
    message: `must be in the format XXXX-XXXX`,
  },
  orderId: {
    regex: /^[0-9]{10}$/,
    message: `must be a valid Kit Id - XXXX-XXXX`,
  },
  trackingNumber: {
    regex: /^#?\s*([a-z]{2}\s*\d{4}\s*\d{4}\s*\d{1}\s*gb)\s*#?$/i,
    message: `is not valid, please check you’ve entered it correctly.`,
  },
  zendeskTicketNumber: {
    regex: /^[0-9]{2,6}$/,
    message: 'must be a valid Zendesk Ticket Number',
  },
  customerId: {
    regex: /^[a-zA-z0-9]{4}-[a-zA-z0-9]{4}$/,
    message: `must be a valid Customer Id - XXXX-XXXX`,
  },
  discountCode: {
    regex: /^[a-zA-z0-9]{4,}$/,
    message: `must be a valid Discount Code`,
  },
}

export type Schema<Values extends FormikValues = FormikValues> = {
  [key in keyof Values]: ValidateProps<Values> | ObjectSchema<AnyObject> | BaseSchema
}

export const schema = function <Values extends FormikValues = FormikValues>(criteria: Schema<Values>) {
  const validationSchema: AnyObject = {}

  Object.entries(criteria).forEach(([key, fieldCriteria]) => {
    if (
      [
        'number',
        'string',
        'text',
        'textarea',
        'radio',
        'radiogroup',
        'checkbox',
        'boolean',
        'boolean_radio',
        'boolean_checkbox',
        'date',
        'datepicker',
        'dob',
        'file',
        'select',
        'multiselect',
      ].includes(fieldCriteria['type'] as ValidateProps<Values>['type'])
    ) {
      const currentCriteria = fieldCriteria as ValidateProps<Values>

      if (currentCriteria.mustMatch && !currentCriteria.mustMatchLabel) {
        currentCriteria.mustMatchLabel = (criteria[currentCriteria.mustMatch] as ValidateProps<Values>).label
      }

      if (currentCriteria.mustNotMatch && !currentCriteria.mustNotMatchLabel) {
        currentCriteria.mustNotMatchLabel = (criteria[currentCriteria.mustNotMatch] as ValidateProps<Values>).label
      }

      validationSchema[key] = validate<Values>(currentCriteria)
    } else {
      validationSchema[key] = fieldCriteria as ObjectSchema<AnyObject>
    }
  })

  return yup.object().shape(validationSchema)
}

type ValueOf<T> = T[keyof T]

export type YupSchema = StringSchema | ArraySchema<AnySchema> | ObjectSchema<AnyObject> | DateSchema

export type ValidateProps<Values extends FormikValues = FormikValues> =
  | ValidatePropsString<Values>
  | ValidatePropsRadio
  | ValidatePropsCheckbox
  | ValidatePropsBoolean
  | ValidatePropsDate
  | ValidatePropsFile

export type ValidateDependsValue = string | number | boolean | null | undefined

export type ValidateDepends =
  | {
      name: string
      value: ValidateDependsValue[] | ValidateDependsValue
    }
  | {
      all: ValidateDepends[]
    }

export type ValidatePropsShared = {
  label: string
  required?: boolean
  depends?: ValidateDepends | ValidateDepends[]
}

export type ValidatePropsString<Values extends FormikValues = FormikValues> = {
  type: 'string' | 'text' | 'textarea' | 'number'
  criteria?: FormValidationCriteria | FormValidationCriteria[]
  min?: number
  max?: number
  all?: never
  mustMatch?: keyof Values
  mustMatchLabel?: string
  mustNotMatch?: keyof Values
  mustNotMatchLabel?: string
  sensitiveMatch?: boolean
} & ValidatePropsShared

export type ValidatePropsRadio = {
  type: 'radio' | 'radiogroup' | 'select'
  criteria?: never
  min?: never
  max?: never
  all?: never
  mustMatch?: never
  mustMatchLabel?: never
  mustNotMatch?: never
  mustNotMatchLabel?: never
  sensitiveMatch?: never
} & ValidatePropsShared

export type ValidatePropsCheckbox = {
  type: 'checkbox' | 'multiselect'
  criteria?: never
  min?: number
  max?: number
  all?: boolean
  mustMatch?: never
  mustMatchLabel?: never
  mustNotMatch?: never
  mustNotMatchLabel?: never
  sensitiveMatch?: never
} & ValidatePropsShared

export type ValidatePropsBoolean = {
  type: 'boolean' | 'boolean_radio' | 'boolean_checkbox'
  criteria?: never
  min?: never
  max?: never
  all?: never
  mustMatch?: never
  mustMatchLabel?: never
  mustNotMatch?: never
  mustNotMatchLabel?: never
  sensitiveMatch?: never
} & ValidatePropsShared

export type ValidatePropsDate = {
  type: 'date' | 'datepicker' | 'dob'
  criteria?: never
  min?: Date | DateStamp | string
  max?: Date | DateStamp | string
  all?: never
  mustMatch?: never
  mustMatchLabel?: never
  mustNotMatch?: never
  mustNotMatchLabel?: never
  sensitiveMatch?: never
} & ValidatePropsShared

export type ValidatePropsFile = {
  type: 'file'
  criteria?: never
  min?: never
  max?: number
  all?: never
  mustMatch?: never
  mustMatchLabel?: never
  mustNotMatch?: never
  mustNotMatchLabel?: never
  sensitiveMatch?: never
} & ValidatePropsShared

export const validate = <Values extends FormikValues = FormikValues>({
  type = 'string',
  label,
  depends,
  ...props
}: ValidateProps<Values>) => {
  let validation: any

  switch (type) {
    case 'number':
      validation = yup.number().label(label)
      break
    case 'string':
    case 'text':
    case 'textarea':
      validation = yup.string().label(label)
      break
    case 'select':
    case 'radiogroup':
    case 'radio':
      validation = yup.string().label(label)
      break
    case 'multiselect':
    case 'checkbox':
      validation = yup.array().label(label)
      break
    case 'boolean':
    case 'boolean_radio':
    case 'boolean_checkbox':
      validation = yup.string().label(label)
      break
    case 'date':
      validation = yup
        .date()
        .label(label)
        .transform((value) => {
          if (value instanceof Date && !isNaN(+value)) {
            return value
          } else if (typeof value === 'string') {
            return fromStringDate(value).toJSDate()
          } else {
            return undefined
          }
        })
      break
    case 'datepicker':
      validation = chronoDate().label(label).typeError(`${label} must be a valid date`)
      break
    case 'dob':
      validation = chronoDate()
        .label(label)
        .typeError(`${label} must be a valid date`)
        .test('is-today', `${label} cannot be the current date`, (value) => {
          if (!value) return true

          try {
            return !isToday(value.toISOString())
          } catch (err) {
            return false
          }
        })
        .test('is-future-date', `${label} cannot be in the future`, (value) => {
          if (!value) return true
          try {
            return isFutureDate(value?.toISOString())
          } catch (err) {
            return false
          }
        })
        .test('is-reasonable-age', `${label} must match an age between 1 day and 110 years old`, (value) => {
          if (!value) return true
          try {
            return isReasonableAge(value?.toISOString())
          } catch (err) {
            return true
          }
        })
      break

    case 'file':
      validation = yup.mixed().label(label)
      break
    default:
      throw new Error(`Unknown type: ${type}`)
  }

  if (depends) {
    validation = validation.when(
      'any',
      (current: ValueOf<Values>, field: YupSchema, { parent }: { parent: Values }) => {
        return dependsMatch(depends, parent) ? validationRules({ validation: field, type, label, ...props }) : field
      },
    )
  } else {
    validation = validationRules({ validation, type, label, ...props })
  }

  return validation
}

const validationRules = <Values extends FormikValues = FormikValues>({
  type = 'string',
  label,
  required = false,
  criteria,
  min,
  max,
  all,
  mustMatch,
  mustMatchLabel,
  mustNotMatch,
  mustNotMatchLabel,
  sensitiveMatch,
  validation,
}: Omit<ValidateProps<Values>, 'depends'> & {
  validation: YupSchema
}) => {
  switch (type) {
    case 'number':
    case 'string':
    case 'text':
    case 'textarea':
      if (min) {
        validation = (validation as StringSchema).min(min as number, `${label} must be a minimum of ${min} characters`)
      }

      if (max) {
        validation = (validation as StringSchema).max(max as number, `${label} must be a maxiumum of ${max} characters`)
      }

      if (criteria) {
        if (!Array.isArray(criteria)) {
          criteria = [criteria]
        }

        const regexMatches = Object.values(pick(validationCriterias, criteria))

        regexMatches.forEach((match) => {
          validation = (validation as StringSchema).matches(match.regex, {
            excludeEmptyString: !required,
            message: (
              <Fragment>
                {label} {match.message}
              </Fragment>
            ),
          })
        })
      }

      if (mustMatch) {
        validation = (validation as StringSchema).test(
          `matches-${String(mustMatch)}`,
          mustMatchLabel ? `"${label}" does not match field "${mustMatchLabel}"` : 'Must Match',
          (value: string | undefined, { parent }: { parent: Values }) => {
            return sensitiveMatch
              ? value?.trim() === parent[mustMatch]?.trim()
              : value?.trim().toUpperCase() === parent[mustMatch]?.trim().toUpperCase()
          },
        )
      }

      if (mustNotMatch) {
        validation = (validation as StringSchema).test(
          `not-matches-${String(mustNotMatch)}`,
          mustNotMatchLabel ? `"${label}" must be different then "${mustNotMatchLabel}"` : 'Must Not Match',
          (value: string | undefined, { parent }: { parent: Values }) => {
            const currentValue = value?.trim().toUpperCase()
            const mustNotMatchValue = parent[mustNotMatch]?.trim().toUpperCase()

            return currentValue !== mustNotMatchValue
          },
        )
      }

      break
    case 'select':
    case 'radiogroup':
    case 'radio':
      break
    case 'multiselect':
    case 'checkbox':
      if (min) {
        if (all) {
          validation = (validation as ArraySchema<AnySchema>).min(
            min as number,
            `All boxes must be selected for ${label}`,
          )
        } else {
          validation = (validation as ArraySchema<AnySchema>).min(
            min as number,
            `Must select a minimum of ${min} boxes for ${label}`,
          )
        }
      }

      if (max) {
        validation = (validation as ArraySchema<AnySchema>).max(
          max as number,
          `Must select a maximum of ${max} boxes for ${label}`,
        )
      }
      break
    case 'boolean':
    case 'boolean_radio':
    case 'boolean_checkbox':
      break
    case 'date':
      if (min) {
        if (typeof min === 'string') {
          min = fromStringDate(min)
        }

        if (min instanceof DateStamp) {
          min = (min as DateStamp).toJSDate()
        }

        validation = (validation as DateSchema).min(
          min,
          `${label} must be a minimum date of ${toStringDate(importDate(min as Date))}`,
        )
      }

      if (max) {
        if (typeof max === 'string') {
          max = fromStringDate(max)
        }

        if (max instanceof DateStamp) {
          max = (max as DateStamp).toJSDate()
        }

        validation = (validation as DateSchema).max(
          max,
          `${label} must be a maximum date of ${toStringDate(importDate(max as Date))}`,
        )
      }

      break
    case 'datepicker':
      if (min) {
        validation = (validation as DateSchema).min(
          min,
          `${label} must be a minimum date of ${toStringDate(fromIsoString(min as string))}`,
        )
      }

      if (max) {
        validation = (validation as DateSchema).max(
          max,
          `${label} must be a maximum date of ${toStringDate(fromIsoString(max as string))}, 23:45`,
        )
      }
      break
    case 'dob':
      break

    case 'file':
      if (max) {
        validation = (validation as ObjectSchema<AnyObject>).test('fileSize', 'The file is too large', (value) => {
          if (!value || !value.length) return true // attachment is optional
          return value[0].size <= (max as number) * 1000000
        })
      }
      break
    default:
      throw new Error(`Unknown type: ${type}`)
  }

  if (required) {
    if (['boolean', 'boolean_radio', 'boolean_checkbox'].includes(type)) {
      validation = (validation as StringSchema).oneOf(['true'], `${label} is required`)
    } else if (['string', 'text', 'textarea', 'radio', 'radiogroup', 'select'].includes(type)) {
      validation = (validation as StringSchema).notOneOf([undefined, ''], `${label} is required`)
    } else {
      validation = validation.required(`${label} is required`)
    }
  }

  return validation
}

export const dependsParseValue = (v: QuestionDependsValue) => (['number', 'boolean'].includes(typeof v) ? `${v}` : v)

export const dependsMatch = (
  depends: ValidateDepends | ValidateDepends[],
  values: FormikValues,
): ValidateDependsValue | ValidateDependsValue[] => {
  if (Array.isArray(depends)) {
    const results = depends.map((target) => dependsMatch(target, values))

    return results.includes(true)
  } else {
    if ('all' in depends) {
      const results = depends.all.map((target) => dependsMatch(target, values))

      return results.every((i) => i === true)
    } else {
      const target = values[depends.name]

      if (Array.isArray(depends.value)) {
        const source = depends.value.map((v) => dependsParseValue(v))

        if (Array.isArray(target)) {
          return intersection(source, target).length > 0
        } else {
          return source.includes(target)
        }
      } else {
        const source = dependsParseValue(depends.value)

        if (Array.isArray(target)) {
          return target.includes(source)
        } else {
          return source === target
        }
      }
    }
  }
}

export const chronoDate = () => {
  const invalidDate = DateTime.invalid('Invalid Date')

  return yup.date().transform(function (value, originalValue) {
    value = chrono.en.GB.parseDate(originalValue)
    return value ? value : invalidDate
  })
}

export const isToday = (value: string): boolean => {
  const date = importDate(chrono.en.GB.parseDate(value) as Date)
  const today = now()

  return date.hasSame(today, 'day')
}

export const isFutureDate = (value: string): boolean => {
  const date = importDate(chrono.en.GB.parseDate(value) as Date)
  const today = now()

  return date < today
}

export const isReasonableAge = (value: string): boolean => {
  const date = importDate(chrono.en.GB.parseDate(value) as Date)
  const diffYears = now().diff(date, 'years').years
  const diffDays = now().diff(date.startOf('day'), 'days').days

  return diffDays >= 1 && diffYears >= 0 && diffYears <= 110
}

export const isOlderThanOneMonth = (value: string): boolean => {
  const date = importDate(chrono.en.GB.parseDate(value) as Date)
  const diffInDays = now().endOf('day').diff(date, 'days').days

  return diffInDays <= 30
}

export const isMoreThanOneDay = (value: string): boolean => {
  const date = importDate(chrono.en.GB.parseDate(value) as Date)
  const diffInDays = date.diff(now().startOf('day'), 'days').days

  return diffInDays <= 1
}

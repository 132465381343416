import * as UI from '@/ui'

export type NavbarGridSize = UI.GridSizes

export type NavbarProps = {
  gridSize?: NavbarGridSize
  children: React.ReactNode
}

export const Navbar = ({ gridSize = 3, children }: NavbarProps) => (
  <div className="px-5 bg-gray-700 border-b border-gray-500">
    <UI.Grid size={gridSize} className="bg-gray-700">
      {children}
    </UI.Grid>
  </div>
)

Navbar.displayName = 'Navbar.Navbar'

export default Navbar

import { createContext, useEffect, useState, useContext, Context } from 'react'
import theme from '../../tailwind-theme'
import { Breakpoint, BreakpointList } from './use-breakpoint'

export type WidthContextType = {
  width: number
  minBreakpoints: BreakpointList
  maxBreakpoints: BreakpointList
  breakpoint: Breakpoint
  breakpointChain: Breakpoint[]
}

const isBrowser = typeof window !== 'undefined'

export const WidthContext = createContext<WidthContextType | undefined>(undefined)
export const WidthProvider = ({ children }: { children: React.ReactNode }) => {
  const [width, setWidth] = useState<number>(isBrowser ? window.innerWidth : 0)
  const [breakpoint, setBreakpoint] = useState<Breakpoint>(getBreakpoint(width))

  useEffect(() => {
    function handleWindowResize() {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleWindowResize)

    setWidth(window.innerWidth)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  useEffect(() => {
    setBreakpoint(getBreakpoint(width))
  }, [width])

  return (
    <WidthContext.Provider
      value={{
        width: width,
        breakpoint: breakpoint || 'default',
        minBreakpoints: minBreakpoints(),
        maxBreakpoints: maxBreakpoints(),
        breakpointChain: breakpointChain(breakpoint || 'default'),
      }}
    >
      {children}
    </WidthContext.Provider>
  )
}

export const useWidth = () => useContext<WidthContextType>(WidthContext as Context<WidthContextType>)

const getBreakpoint = (width: number) => {
  const breakpoints = maxBreakpoints()
  return Object.entries(breakpoints).find(([breakpointWidth]) => width <= +breakpointWidth)?.[1] || 'default'
}

const breakpointChain = (breakpoint: Breakpoint) => {
  const breakpointNames: Breakpoint[] = ['default', ...Object.keys(theme.screens)]
  return breakpointNames.slice(0, breakpointNames.indexOf(breakpoint) + 1).reverse()
}

const minBreakpoints = () => {
  const breakpoints: BreakpointList = { 0: 'default' }
  if (theme?.screens) {
    Object.entries(theme.screens).forEach(([key, value]) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      breakpoints[+(value as string).replace('px', '')] = key
    })
  }
  return breakpoints
}

const maxBreakpoints = () => {
  const min = minBreakpoints()
  const breakpoints: BreakpointList = {}

  for (let i = 0; i < Object.keys(min).length; i++) {
    breakpoints[+Object.keys(min)[i + 1] || 9999] = Object.values(min)[i]
  }

  return breakpoints
}

import { DateTime } from 'luxon'

// https://moment.github.io/luxon/demo/global.html

// Note use [DateStamp].toJSDate() to get Javascript Date

export type DateStamp = DateTime

export const DateStamp = DateTime

export const now = (): DateStamp => DateTime.now()

export const importDate = (date: Date): DateTime => DateTime.fromJSDate(date)

/**
 * @param date - DateTime in ISO format ('2024-06-28T11:20:00')
 * @returns string - Time in 12-hour format ('11:20 AM')
 */
export const toTime = (date: string): string => fromIsoString(date).toFormat('h:mm a')

export const toRegionalDate = (date: DateStamp): string => date.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)

export const toRegionalDateOnly = (date: DateStamp): string => date.toLocaleString(DateTime.DATE_FULL)

export const toStringDate = (date: DateStamp): string => date.toLocaleString(DateTime.DATE_SHORT)
export const toStringDateTime = (date: DateStamp): string => date.toLocaleString(DateTime.DATETIME_SHORT)
export const fromStringDate = (date: string): DateStamp =>
  DateTime.fromFormat(date, 'dd/MM/yyyy', { zone: 'Europe/London' })

export const fromIsoString = (date: string): DateStamp => DateTime.fromISO(date)
export const toIsoString = (date: DateStamp): string => {
  const validToIso = date.toISO()
  if (!validToIso) {
    throw new Error('Failed to convert to ISO string')
  }

  return validToIso
}
export const toIsoDate = (date: DateTime): string => {
  const validToIsoDate = date.toISODate()
  if (!validToIsoDate) {
    throw new Error('Failed to convert to ISO date')
  }

  return validToIsoDate
}

export const fromDateTimeString = (date: string): DateStamp => DateTime.fromFormat(date, 'dd/MM/yyyy HH:mm:ss')
export const fromUnixTime = (date: number): DateStamp => DateTime.fromMillis(date)

export const fromExcelDate = (date: number): DateStamp => {
  const hours = Math.floor((date % 1) * 24)
  const minutes = Math.floor(((date % 1) * 24 - hours) * 60)
  // eslint-disable-next-line no-restricted-syntax
  return importDate(new Date(Date.UTC(0, 0, date, hours - 17, minutes))).minus({ hour: 7 })
}

/**
 * @param dateStr - Date string in ISO format ('2024-06-01')
 * @returns Object: {dayOfWeek: 'Monday', restOfDate: 'June 1st, 2024'}
 */
export const toDetailedDate = (dateStr: string) => {
  const date = fromIsoString(dateStr)

  const getDaySuffix = (day: number): string => {
    if (day > 3 && day < 21) return 'th'
    switch (day % 10) {
      case 1:
        return 'st'
      case 2:
        return 'nd'
      case 3:
        return 'rd'
      default:
        return 'th'
    }
  }

  const dayOfWeek = date.toFormat('cccc')
  const suffix = getDaySuffix(date.day)
  const restOfDate = date.toFormat(`LLLL d'${suffix}', yyyy`)

  return { dayOfWeek, restOfDate }
}

/**
 * @param timeslot - "2024-07-12T10:00:00Z" date-time string in an ISO 8601 date-time format where the time is in Coordinated Universal Time (UTC)
 * @returns string: 'Friday 12 July, at 10:00 AM'
 */
export const getFormattedDateTime = (timeslot: string): string =>
  DateTime.fromISO(timeslot, { zone: 'Europe/London', setZone: true }).toFormat("cccc dd LLLL, 'at' h:mm a")

export const isUKHoliday = (inst: DateTime): boolean => {
  const ukHolidays = [
    DateTime.fromObject({ year: 2024, month: 12, day: 25 }),
    DateTime.fromObject({ year: 2024, month: 12, day: 26 }),
    DateTime.fromObject({ year: 2025, month: 1, day: 1 }),
    DateTime.fromObject({ year: 2025, month: 4, day: 18 }),
    DateTime.fromObject({ year: 2025, month: 4, day: 21 }),
    DateTime.fromObject({ year: 2025, month: 5, day: 5 }),
    DateTime.fromObject({ year: 2025, month: 5, day: 26 }),
    DateTime.fromObject({ year: 2025, month: 8, day: 25 }),
    DateTime.fromObject({ year: 2025, month: 12, day: 25 }),
    DateTime.fromObject({ year: 2025, month: 12, day: 26 }),
  ]
  return ukHolidays.some((holiday) => holiday.hasSame(inst, 'day'))
}

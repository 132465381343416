import { useField, useFormikContext } from 'formik'
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid'
import { useEffect } from 'react'
import { useUi } from '../../use-ui'
import { dependsMatch, ValidateDepends, ValidateDependsValue } from '../validation'
import { type FilePropsCommon, boxSizes } from './index'
import * as UI from '@/ui'

export type FormikFileProps = {
  depends?: ValidateDepends | ValidateDepends[]
  setIsHidden?: (hidden: boolean) => void
} & FilePropsCommon

export const FormikFile = ({
  name,
  errorMessage,
  boxSize,
  placeholder,
  depends,
  setIsHidden,
  ...props
}: FormikFileProps) => {
  const { isSubmitting, values } = useFormikContext()
  const [, meta, { setTouched, setValue }] = useField({ name, ...props })

  useEffect(() => {
    if (depends) {
      setIsHidden && setIsHidden(depends && !dependsMatch(depends, values as { [key: string]: ValidateDependsValue }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  useEffect(() => {
    if (isSubmitting) {
      setTouched(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting])

  errorMessage = meta.touched && meta.error ? meta.error : errorMessage

  const { className } = useUi({
    styles: {
      boxSize: { options: boxSizes, selected: boxSize },
    },
    name: 'Form.File',
    className: `border border-gray-500 bg-white p-2 ${
      errorMessage
        ? 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 placeholder-red-300'
        : meta.touched &&
          'focus:ring-green-500 focus:border-green-500 border-green-500 text-green-900 placeholder-green-300'
    }`,
  })

  return (
    <>
      <div className="mt-1 relative rounded-md shadow-sm">
        <div className={className}>
          <input
            type="file"
            onChange={(e) => e.currentTarget?.files && setValue(e.currentTarget?.files[0])}
            placeholder={placeholder}
            {...props}
          />
          {errorMessage && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
            </div>
          )}

          {meta.touched && !errorMessage && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <CheckCircleIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
            </div>
          )}
        </div>
      </div>

      {errorMessage && <UI.Form.Error>{errorMessage}</UI.Form.Error>}
    </>
  )
}

FormikFile.displayName = 'Form.File.FormikFile'

export default FormikFile

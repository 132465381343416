import { createRef, useEffect, useState, Fragment } from 'react'
import { useFormikContext } from 'formik'
import { isEmpty } from 'lodash-es'
import { TooltipProps } from '../index'
import { useBreakpoint, Breakpoints } from '../use-breakpoint'
import { Errors } from './form/errors'
import * as UI from '@/ui'

export type SubmitProps = { tooltipPosition?: Breakpoints<TooltipProps['position']>; showError?: boolean } & Omit<
  UI.ButtonProps,
  'type'
>

export const Submit = ({
  children,
  className,
  errorMessage,
  showError = true,
  tooltipPosition = 'left',
  onClick,
  ...props
}: Omit<SubmitProps, 'uiComponent'>) => {
  const { errors, isSubmitting, isValidating, setErrors } = useFormikContext()
  const [disabled, setButtonDisabled] = useState<boolean>()

  const [isClicked, setIsClicked] = useState<boolean>(false)

  const numberOfErrors = isClicked ? Object.keys(errors).length : 0

  useEffect(() => {
    setButtonDisabled(numberOfErrors > 0)
  }, [numberOfErrors])

  const belowButtonRef = createRef<HTMLAnchorElement>()

  const [hasValidationError, setHasValidationError] = useState<boolean>()

  useEffect(() => {
    if (isSubmitting && isValidating && !isEmpty(errors)) {
      setHasValidationError(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, isValidating])

  useEffect(() => {
    if (isClicked && !isValidating && (hasValidationError || errorMessage)) {
      setHasValidationError(false)
      belowButtonRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClicked, isValidating, hasValidationError, errorMessage])

  const tooltipErrorMessage = ((isClicked && numberOfErrors > 0) || errorMessage) && (
    <Fragment>
      {numberOfErrors > 0 && <Fragment>{numberOfErrors} field(s) have errors</Fragment>}
      {numberOfErrors > 0 && errorMessage && (
        <>
          <br />
          <br />
        </>
      )}
      {errorMessage}
    </Fragment>
  )

  const click = (e: React.MouseEvent<HTMLButtonElement>) => {
    setErrors({})
    setIsClicked(true)
    onClick && onClick(e)
  }

  const currentSize = useBreakpoint(props.size) as string

  const currentTooltipPosition = useBreakpoint(tooltipPosition)

  return (
    <div>
      <UI.Block gap="xs">
        <UI.Tooltip
          message={tooltipErrorMessage}
          position={currentTooltipPosition}
          width={currentSize?.match(/full/i) ? 'full' : 'medium'}
          size={'medium'}
          color="danger"
        >
          <UI.Button
            type="submit"
            onClick={click}
            disabled={disabled}
            className={className}
            uiComponent="Form.Submit"
            isRunning={isSubmitting}
            {...(props as UI.ButtonProps)}
          >
            {children}
          </UI.Button>
        </UI.Tooltip>
        <div className="relative">
          <span
            className={`absolute w-[200px] right-0 top-2 ${
              currentTooltipPosition === 'left' ? 'text-right right-0' : 'left-0'
            } `}
          >
            {showError && <Errors message={errorMessage} isClicked={isClicked} />}
          </span>
        </div>
      </UI.Block>
      <a ref={belowButtonRef}></a>
    </div>
  )
}

Submit.displayName = 'Form.Submit'

export default Submit

import * as chrono from 'chrono-node'
import { DateTime } from 'luxon'
import HelpText from '../help-text'
import Label from '../label'
import { type Breakpoints } from '../../use-ui'
import FormikDatePicker, { FormikDatePickerProps } from './formik-date-picker'
import FormikDateTimePicker, { FormikDateTimePickerProps } from './formik-date-time-picker'
import * as UI from '@/ui'

export type DateSize = 'medium'

export type DatePickerProps = DatePickerPropsFormik | DateTimePickerPropsFormik

export type DatePickerPropsCommon = {
  name: string
  label?: string
  field?: 'date' | 'dateOfBirth' | 'datetime'
  placeholder?: string
  errorMessage?: string
  value?: string
  chronoInstance?: chrono.Chrono
  containerClassName?: string
  boxSize?: Breakpoints<DateSize>
  max?: DateTime | string
  min?: DateTime | string
  readOnly?: string | undefined
  dobFormatter?: (age: string) => React.ReactElement
} & Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'type' | 'readOnly'>

export type DatePickerPropsShared = {
  helpTextAbove?: string
  helpTextBelow?: string

  children?: React.ReactNode
}

export type DatePickerPropsFormik = FormikDatePickerProps &
  DatePickerPropsShared & {
    type?: 'date-picker'
  }

export type DateTimePickerPropsFormik = FormikDateTimePickerProps &
  DatePickerPropsShared & {
    type?: 'datetime-picker'
  }

export const boxSizes = {
  default: 'block w-full pr-10 focus:outline-none sm:text-sm rounded-md',
  medium: '',
}

export const DatePicker = ({
  name,
  field,
  label,
  helpTextAbove,
  helpTextBelow,
  children,
  containerClassName = '',
  ...props
}: DatePickerProps) => {
  return (
    <div className={containerClassName}>
      {children && (
        <Label htmlFor={field === 'date' ? `datepicker-${name}` : `datetimepicker-${name}`}>{children}</Label>
      )}

      <UI.Block gap="xs">
        {helpTextAbove && <HelpText className="-mb-1">{helpTextAbove}</HelpText>}

        {field === 'datetime' && <FormikDateTimePicker name={name} label={label} {...props} />}
        {(field === 'date' || field === 'dateOfBirth') && <FormikDatePicker field={field} name={name} {...props} />}

        {helpTextBelow && <HelpText>{helpTextBelow}</HelpText>}
      </UI.Block>
    </div>
  )
}

DatePicker.displayName = 'Form.DatePicker'

export default DatePicker

import { ExclamationCircleIcon, XCircleIcon, CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/24/solid'
import { XMarkIcon, PlusCircleIcon, MinusCircleIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useUi } from './use-ui'
import * as UI from '@/ui'

export type MessageColors = 'info' | 'success' | 'danger' | 'warning'

export type MessageProps = {
  title?: string
  titleSize?: string
  color: MessageColors
  className?: string
  children: React.ReactNode
  canDismiss?: boolean
  accordion?: boolean
  clearMessage?: () => void
}

export const Message = ({
  color,
  title,
  titleSize = 'medium',
  className,
  canDismiss = true,
  children,
  clearMessage,
  accordion = false,
}: MessageProps) => {
  const [isClosed, setIsClosed] = useState<boolean>(false)
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  // const colors = {
  //   default: 'rounded-md p-4',
  //
  //   info: 'text-blue-800 bg-blue-100 border-blue-500', // Tailwind: sm:text-blue-800 sm:bg-blue-100 sm:border-blue-500  md:text-blue-800 md:bg-blue-100 md:border-blue-500  lg:text-blue-800 lg:bg-blue-100 lg:border-blue-500  xl:text-blue-800 xl:bg-blue-100 xl:border-blue-500
  //   success: 'text-green-800 bg-green-100 border-green-500', // Tailwind: sm:text-green-800 sm:bg-green-100 sm:border-green-500  md:text-green-800 md:bg-green-100 md:border-green-500  lg:text-green-800 lg:bg-green-100 lg:border-green-500  xl:text-green-800 xl:bg-green-100 xl:border-green-500
  //   danger: 'text-red-800 bg-red-100 border-red-500', // Tailwind: sm:text-red-800 sm:bg-red-100 sm:border-red-500  md:text-red-800 md:bg-red-100 md:border-red-500  lg:text-red-800 lg:bg-red-100 lg:border-red-500  xl:text-red-800 xl:bg-red-100 xl:border-red-500
  //   warning: 'bg-yellow-50 text-sm font-medium text-yellow-800', // Tailwind: sm:text-yellow-800 sm:bg-yellow-100 sm:border-yellow-500  md:text-yellow-800 md:bg-yellow-100 md:border-yellow-500  lg:text-yellow-800 lg:bg-yellow-100 lg:border-yellow-500  xl:text-yellow-800 xl:bg-yellow-100 xl:border-yellow-500
  // }

  const backgroundStyles = useUi({
    styles: {
      colors: {
        options: {
          default: 'rounded-md p-4',
          info: 'bg-blue-50',
          success: 'bg-green-50',
          danger: 'bg-red-50',
          warning: 'bg-yellow-50',
        },
        selected: color,
      },
    },
    name: 'Message',
    className,
  })

  const iconStyles = useUi({
    styles: {
      colors: {
        options: {
          default: 'h-6 w-6',
          info: 'text-selphBlue-200',
          success: 'text-green-400',
          danger: 'text-red-400',
          warning: 'text-yellow-400',
        },
        selected: color,
      },
    },
    name: 'Message',
  })

  const titleStyles = useUi({
    styles: {
      colors: {
        options: {
          default: 'font-semibold',
          info: 'text-selphBlue-600',
          success: 'text-green-800',
          danger: 'text-red-800',
          warning: 'text-yellow-800',
        },
        selected: color,
      },
      titleSizes: {
        options: {
          small: 'text-sm',
          medium: 'text-base',
          large: 'text-lg',
          xl: 'text-xl',
        },
        selected: titleSize,
      },
    },
    name: 'Message',
  })

  const messageStyles = useUi({
    styles: {
      colors: {
        options: {
          default: `text-base`,
          info: 'text-selphBlue-500',
          success: 'text-green-700',
          danger: 'text-red-700',
          warning: 'text-yellow-700',
        },
        selected: color,
      },
    },
    name: 'Message',
  })

  const icons = {
    info: InformationCircleIcon,
    success: CheckCircleIcon,
    danger: XCircleIcon,
    warning: ExclamationCircleIcon,
  }

  const Icon = icons[color]

  const onDismiss = () => {
    clearMessage ? clearMessage() : setIsClosed(true)
  }

  return (
    <div className={`${backgroundStyles.className} ${isClosed && 'hidden'}`}>
      <div className="flex justify-between">
        <div className="flex gap-x-2 w-full">
          <Icon className={`${iconStyles.className} shrink-0`} aria-hidden="true" />

          <UI.Block gap="small" className="w-full">
            {(title || accordion) && (
              <div className="flex justify-between">
                {title && <h3 className={titleStyles.className}>{title}</h3>}
                {accordion && (
                  <button type="button" className="rounded-md" onClick={() => setIsExpanded(!isExpanded)}>
                    {isExpanded ? <span className="sr-only">Close</span> : <span className="sr-only">Open</span>}
                    {isExpanded ? (
                      <MinusCircleIcon className="h-6 w-6" aria-hidden="true" />
                    ) : (
                      <PlusCircleIcon className="h-6 w-6" aria-hidden="true" />
                    )}
                  </button>
                )}
              </div>
            )}
            {!accordion ? (
              <div className={messageStyles.className}>
                <UI.Block gap="small">{children}</UI.Block>
              </div>
            ) : (
              isExpanded && (
                <div className={messageStyles.className}>
                  <UI.Block gap="small">{children}</UI.Block>
                </div>
              )
            )}
          </UI.Block>
        </div>
        {canDismiss && (
          <button type="button" className="rounded-md" onClick={() => onDismiss()}>
            <span className="sr-only">Close</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        )}
      </div>
      {/*<div className="flex">*/}
      {/*  <div className="flex-shrink-0">*/}
      {/*    <Icon className={iconStyles.className} aria-hidden="true" />*/}
      {/*  </div>*/}
      {/*  <div className="ml-3">*/}
      {/*    {title && <h3 className={titleStyles.className}>{title}</h3>}*/}
      {/*    <div className={messageStyles.className}>{children}</div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  )
}

Message.displayName = 'Message'

export default Message

import { useState } from 'react'
import { Label } from '../label'
import { HelpText } from '../help-text'
import { FormikRadioGroup, type FormikRadioGroupProps } from './formik-radio-group'
import { InputRadioGroup, type InputRadioGroupProps } from './input-radio-group'
import * as UI from '@/ui'

export type RadioGroupProps = RadioGroupPropsFormik | RadioGroupPropsInput

export type RadioPropsCommon = {
  name: string
  options: { [key: string | number]: string | number | boolean | null | undefined }
  errorMessage?: string
  checkedValue?: string
  gridGap?: UI.GridProps['gap']
  gridSize?: UI.GridProps['size']
  gridAlign?: UI.GridProps['align']
  gridColVerticalAlign?: UI.GridProps['colVerticalAlign']
  readOnly?: 'readOnly'
} & Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'type' | 'readOnly'>

export type RadioGroupPropsShared = {
  helpTextAbove?: string
  helpTextBelow?: string

  children?: React.ReactNode
}

export type RadioGroupPropsFormik = RadioGroupPropsShared &
  FormikRadioGroupProps & {
    type?: 'formik'
  }

export type RadioGroupPropsInput = RadioGroupPropsShared &
  InputRadioGroupProps & {
    type?: 'input'
    depends?: never
    optionStyle?: never
  }

export const RadioGroup = ({
  name,
  type = 'formik',
  children,
  helpTextAbove,
  helpTextBelow,
  gridGap = 'xs',
  gridSize = 1,
  gridAlign,
  gridColVerticalAlign,
  ...props
}: RadioGroupProps) => {
  const [isHidden, setIsHidden] = useState<boolean>(false)

  return (
    <div className={isHidden ? 'hidden' : ''}>
      {children && <Label htmlFor={type === 'formik' ? `input-${name}` : name}>{children}</Label>}

      <UI.Block gap="xs" className="pt-1">
        {helpTextAbove && <HelpText>{helpTextAbove}</HelpText>}

        {type === 'formik' ? (
          <FormikRadioGroup
            name={name}
            gridGap={gridGap}
            gridSize={gridSize}
            gridAlign={gridAlign}
            gridColVerticalAlign={gridColVerticalAlign}
            setIsHidden={setIsHidden}
            {...(props as Omit<
              RadioGroupPropsFormik,
              'name' | 'gridGap' | 'gridSize' | 'gridAlign' | 'gridColVerticalAlign' | 'setIsHidden'
            >)}
          />
        ) : (
          <InputRadioGroup
            name={name}
            gridGap={gridGap}
            gridSize={gridSize}
            gridAlign={gridAlign}
            gridColVerticalAlign={gridColVerticalAlign}
            {...props}
          />
        )}

        {helpTextBelow && <HelpText>{helpTextBelow}</HelpText>}
      </UI.Block>
    </div>
  )
}

RadioGroup.displayName = 'Form.RadioGroup'

export default RadioGroup

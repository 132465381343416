import {
  CalendarIcon,
  IdentificationIcon,
  ChartBarIcon,
  BeakerIcon,
  WifiIcon,
  UserIcon,
  CodeBracketSquareIcon,
} from '@heroicons/react/24/solid'
import { useRouter } from 'next/router'
import { PopoverButton } from '@headlessui/react'
import { getHref } from '../../routes'
import useUser from '../user/use-user'
import * as UI from '@/ui'

const NavigationMenu = () => {
  const { replace: redirect } = useRouter()
  const { logout } = useUser()

  return (
    <UI.Navbar.Navbar gridSize={1}>
      <div>
        <UI.Navbar.NavItem icon={ChartBarIcon} label="Overview" color="yellow" to="admin" />

        <UI.Navbar.NavItem icon={CalendarIcon} label="Orders" color="blue">
          <UI.Block display="flexCol" gap="small">
            <div className="bg-white rounded-md p-2">
              <UI.Form.Form<{ orderId: string }>
                initialValues={{ orderId: '' }}
                validationSchema={UI.Form.validation.schema({
                  orderId: { label: 'Order ID', type: 'string', criteria: 'orderId' },
                })}
                onSubmit={(v) => redirect(getHref(['adminOrder', { id: v['orderId'] }]))}
              >
                <UI.Form.Text name="orderId" placeholder="Order ID" autoFocus></UI.Form.Text>
              </UI.Form.Form>
            </div>

            <PopoverButton as={UI.Link} to="adminRecentShipments" color="white">
              Recent Shipments
            </PopoverButton>

            <PopoverButton as={UI.Link} to="adminSales" color="white">
              Sales Report
            </PopoverButton>

            <PopoverButton as={UI.Link} to="adminOrderCreate" color="white">
              Create New Order
            </PopoverButton>
            <PopoverButton as={UI.Link} to="adminBespokeTestLink" color="white">
              Create Bespoke Test Link
            </PopoverButton>
          </UI.Block>
        </UI.Navbar.NavItem>

        <UI.Navbar.NavItem icon={IdentificationIcon} label="Kits" color="purple">
          <UI.Block display="flexCol" gap="small">
            <div className="bg-white rounded-md p-2">
              <UI.Form.Form<{ kitId: string }>
                initialValues={{ kitId: '' }}
                validationSchema={UI.Form.validation.schema({
                  orderId: { label: 'Kit ID', type: 'string', criteria: 'kitId' },
                })}
                onSubmit={(v) => redirect(getHref(['adminKit', { id: v['kitId'] }]))}
              >
                <UI.Form.Text name="kitId" placeholder="Kit ID" autoFocus></UI.Form.Text>
              </UI.Form.Form>
            </div>

            <PopoverButton as={UI.Link} to="adminKitGenerateId" color="white">
              Generate Kit IDs
            </PopoverButton>
          </UI.Block>
        </UI.Navbar.NavItem>

        <UI.Navbar.NavItem icon={BeakerIcon} label="Tests" color="purple">
          <UI.Block display="flexCol" gap="small">
            <PopoverButton as={UI.Link} to="adminTestReporting" color="white">
              Test Reporting
            </PopoverButton>

            <PopoverButton as={UI.Link} to={'adminTests'} color="white">
              In Flight
            </PopoverButton>
          </UI.Block>
        </UI.Navbar.NavItem>

        <UI.Navbar.NavItem icon={UserIcon} label="Customers" color="green">
          <div className="bg-white rounded-md p-2">
            <UI.Form.Form<{ criterion: string }>
              initialValues={{ criterion: '' }}
              validationSchema={UI.Form.validation.schema({
                criterion: { label: 'Email', type: 'string', required: true },
              })}
              onSubmit={(v) => redirect(getHref(['adminCustomer', { criterion: v['criterion'] }]))}
            >
              <UI.Form.Text name="criterion" placeholder="Email / user ID" autoFocus></UI.Form.Text>
            </UI.Form.Form>
          </div>
        </UI.Navbar.NavItem>

        <UI.Navbar.NavItem icon={WifiIcon} label="Integrations" color="green">
          <UI.Block display="flexCol" gap="small">
            <PopoverButton as={UI.Link} to="adminIntegrationTDL" color="white">
              TDL
            </PopoverButton>

            <PopoverButton as={UI.Link} to="adminIntegrationRoyalMail" color="white">
              Royal Mail
            </PopoverButton>
          </UI.Block>
        </UI.Navbar.NavItem>

        <UI.Navbar.NavItem icon={CodeBracketSquareIcon} label="Developer" color="orange">
          <UI.Block display="flexCol" gap="small">
            <PopoverButton as={UI.Link} to="adminDeveloperGraphQl" color="white">
              GraphQL
            </PopoverButton>
          </UI.Block>
          <UI.Block display="flexCol" gap="small">
            <PopoverButton as={UI.Link} to="adminLogs" color="white">
              Logs
            </PopoverButton>
          </UI.Block>
        </UI.Navbar.NavItem>

        <UI.Navbar.NavItem label="Logout" className="float-right" color="red" onClick={() => logout()} />
      </div>
    </UI.Navbar.Navbar>
  )
}

export default NavigationMenu

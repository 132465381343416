import { useCallback } from 'react'

export type StorageType = 'session' | 'local'
export type ValueType = any
export type UseStorageReturnValue = {
  getItem: <Value extends ValueType>(key: string, type?: StorageType) => Value
  setItem: (key: string, value: ValueType, type?: StorageType) => boolean
  removeItem: (key: string, type?: StorageType) => void
}

const isBrowser: boolean = ((): boolean => typeof window !== 'undefined')()
const storageType = (type?: StorageType): 'localStorage' | 'sessionStorage' => `${type ?? 'session'}Storage`

export const useStorage = (): UseStorageReturnValue => {
  const getItem = useCallback((key: string, type?: StorageType) => {
    const value = isBrowser ? window[storageType(type)][key] : undefined

    try {
      return JSON.parse(value)
    } catch {
      return value
    }
  }, [])

  const setItem = useCallback((key: string, value: ValueType, type?: StorageType): boolean => {
    if (typeof value === 'object') {
      value = JSON.stringify(value)
    }

    if (isBrowser) {
      window[storageType(type)].setItem(key, value)
      return true
    }

    return false
  }, [])

  const removeItem = useCallback((key: string, type?: StorageType): void => {
    window[storageType(type)].removeItem(key)
  }, [])

  return {
    getItem,
    setItem,
    removeItem,
  }
}

export default useStorage

export const getItem = <Value extends ValueType>(key: string, type?: StorageType): Value | undefined => {
  const value = isBrowser ? window[storageType(type)][key] : undefined

  try {
    return JSON.parse(value)
  } catch {
    return value
  }
}

export const setItem = (key: string, value: ValueType, type?: StorageType): boolean => {
  if (typeof value === 'object') {
    value = JSON.stringify(value)
  }

  if (isBrowser) {
    window[storageType(type)].setItem(key, value)
    return true
  }

  return false
}

export const removeItem = (key: string, type?: StorageType): void => {
  window[storageType(type)].removeItem(key)
}

import { ReactElement, useEffect } from 'react'
import { ApolloProvider } from '@apollo/client'
import { SessionProvider } from 'next-auth/react'
import Script from 'next/script'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { NextPage } from 'next'
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga4'
import { useRouter } from 'next/router'
import { DefaultSeo } from 'next-seo'
import { LayoutProps as LayoutPropsWithChildren } from '../components/layout/layout'
import Tracking from '../components/tracking'
import { BasketProvider } from '../components/checkout/use-basket'
import { NotificationProvider } from '../components/ui/use-notification'
import { WidthProvider } from '../components/ui/use-width'
import { CurrentUserProvider } from '../components/user/use-user'
import { ImpersonationProvider } from '../components/impersonation-provider'
import { LayoutManager } from '../components/layout'
import { MailchimpProvider } from '../components/checkout/use-mailchimp'
import { client } from '../services/apollo-client'
import '../styles/globals.css'

type LayoutProps = Omit<LayoutPropsWithChildren, 'children'>
export type NextPageWithLayout<P = Record<string, never>, IP = P> = NextPage<P, IP> & {
  layoutProps?: LayoutProps | ((props: P) => LayoutProps)
  getLayout?: (page: ReactElement) => ReactElement
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppPropsWithLayout) {
  const { asPath } = useRouter()
  useEffect(() => {
    if (!asPath.includes('admin')) {
      startGa()
    }
  }, [asPath])

  const canonical = `https://www.selph.co.uk${asPath.split('?')[0].replace(/\/$/, '')}`

  const docPage = asPath.includes('privacy-policy') || asPath.includes('cookie-policy')

  return (
    <>
      <Head>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#0d4780" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="facebook-domain-verification" content="dpnjxotb75dfd7r9ritoq4rdhss0ly" />
      </Head>

      {/* CRO Tool Convert */}
      <Script type="text/javascript" src="//cdn-4.convertexperiments.com/js/10041889-10047123.js" async />

      {/* Mouseflow session recording tool */}
      <Script
        id="mouseFlow"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window._mfq = window._mfq || [];
            (function() {
            var mf = document.createElement("script");
            mf.type = "text/javascript"; mf.defer = true;
            mf.src = "//cdn.mouseflow.com/projects/e3f19fed-ba46-4ec0-8aab-a892017264f2.js";
            document.getElementsByTagName("head")[0].appendChild(mf);
            })();`,
        }}
      />

      {!docPage && <Tracking />}
      <DefaultSeo {...{ canonical: canonical }} />

      <SessionProvider session={session} refetchOnWindowFocus={true} refetchInterval={5 * 60}>
        <ApolloProvider client={client}>
          <CurrentUserProvider>
            <ImpersonationProvider>
              <BasketProvider>
                <MailchimpProvider>
                  <WidthProvider>
                    <NotificationProvider>
                      <LayoutManager Component={Component} pageProps={pageProps} />
                    </NotificationProvider>
                  </WidthProvider>
                </MailchimpProvider>
              </BasketProvider>
            </ImpersonationProvider>
          </CurrentUserProvider>
        </ApolloProvider>
      </SessionProvider>
    </>
  )
}

export default MyApp

function startGa() {
  if (process.env.NODE_ENV === 'production') {
    TagManager.initialize({ gtmId: 'GTM-N3XK44R' })
  }
  ReactGA.set({ anonymizeIp: true, branch: process.env.NEXT_PUBLIC_BRANCH })
}

import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'
import * as UI from '@/ui'

export type InputPasswordProps = {
  label: string
  name: string
  placeholder: string
  required?: boolean
  className?: string
}

export const InputPassword = ({ name, label, placeholder }: InputPasswordProps) => {
  const [passwordVisible, setPasswordVisible] = useState(false)

  return (
    <UI.Block gap={'xs'}>
      <label htmlFor="password" className="font-medium">
        {label}
      </label>
      <div className="relative w-full">
        <input
          name={name}
          type={passwordVisible ? 'text' : 'password'}
          placeholder={placeholder}
          className="relative w-full border rounded-md py-2 px-3 pr-10 text-sm leading-tight focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
        />

        <button
          type="button"
          onClick={() => setPasswordVisible(!passwordVisible)}
          className="absolute inset-y-0 right-0 pl-3 flex items-center"
        >
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
            {passwordVisible ? (
              <>
                <span className="text-sm text-gray-500 w-40">Password is shown</span>
                <EyeSlashIcon className="h-6 w-6 text-gray-500 hover:text-blue-500" />
              </>
            ) : (
              <>
                <span className="text-sm text-gray-500 w-40">Password is hidden</span>
                <EyeIcon className="h-6 w-6 text-gray-500 hover:text-blue-500" />
              </>
            )}
          </div>
        </button>
      </div>
    </UI.Block>
  )
}

InputPassword.displayName = 'Form.InputPassword'

export default InputPassword

export type ErrorProps = {
  className?: string
  children: React.ReactNode | string
}

export const Error = ({ className, children }: ErrorProps) => {
  return typeof children === 'string' ? (
    <p className={`text-red-500 text-sm ${className}`}>{children}</p>
  ) : (
    <div className={`text-red-500 text-sm ${className}`}>{children}</div>
  )
}

Error.displayName = 'Form.Error'

export default Error

import { map } from 'lodash-es'
import { useUi } from '../../use-ui'
import { type CheckboxPropsCommon } from './index'
import * as UI from '@/ui'

export type InputCheckboxGroupProps = CheckboxPropsCommon

export const InputCheckboxGroup = ({
  name,
  options,
  errorMessage,
  gridGap,
  gridSize,
  gridAlign,
  gridColVerticalAlign,
  ...props
}: InputCheckboxGroupProps) => {
  const { className } = useUi({
    name: 'Form.CheckboxGroup',
  })

  return (
    <UI.Block gap="xs">
      <UI.Grid gap={gridGap} size={gridSize} align={gridAlign} colVerticalAlign={gridColVerticalAlign}>
        {map(options, (value, label) => (
          <div key={value + label} className={gridSize === 'flex' ? 'mr-5' : ''}>
            <UI.Form.Label type="option" text={label}>
              <input type="checkbox" name={name} value={value as string} className={className} {...props} />
            </UI.Form.Label>
          </div>
        ))}
      </UI.Grid>

      {errorMessage && <UI.Form.Error>{errorMessage}</UI.Form.Error>}
    </UI.Block>
  )
}

InputCheckboxGroup.displayName = 'Form.CheckboxGroup.InputCheckboxGroup'

export default InputCheckboxGroup

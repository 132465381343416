import * as UI from '@/ui'

export type BooleanProps = BooleanPropsCheckbox | BooleanPropsRadio

export type BooleanPropsCheckbox = {
  field?: 'checkbox'
} & UI.Form.CheckboxProps

export type BooleanPropsRadio = {
  field?: 'radio'
  label?: never
} & Omit<UI.Form.RadioGroupProps, 'options'>

export const Boolean = ({ field = 'checkbox', ...props }: BooleanProps) => (
  <>
    {field === 'checkbox' && <UI.Form.Checkbox {...(props as UI.Form.CheckboxProps)} />}
    {field === 'radio' && (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <UI.Form.RadioGroup
        {...{ options: { Yes: true, No: false }, ...(props as Omit<UI.Form.RadioGroupProps, 'options'>) }}
      />
    )}
  </>
)

export default Boolean

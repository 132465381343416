import * as UI from '@/ui'

export type InputEmailProps = {
  name: string
  label: string
  placeholder: string
}

export const InputEmail = ({ name, label, placeholder }: InputEmailProps) => {
  return (
    <UI.Block gap={'xs'}>
      <label htmlFor={name} className="font-medium">
        {label}
      </label>
      <input
        name={name}
        type="email"
        placeholder={placeholder}
        className="w-full border rounded-md py-2 px-3 pr-10 text-sm leading-tight focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
      />
    </UI.Block>
  )
}

InputEmail.displayName = 'Form.InputEmail'

export default InputEmail

import { isEmpty } from 'lodash-es'
import Message from './message'
import { useNotification, Notification as NotificationType } from './use-notification'

export type NotificationProps = NotificationType & {
  className?: string
}

export const Notification = ({ className, ...props }: NotificationProps) => {
  const { clearMessage } = useNotification()
  if (props && !isEmpty(props)) {
    if (props.success) {
      return (
        <div className="fixed top-5 left-1/2 -translate-x-1/2 z-40 md:w-4/5 w-full px-5">
          <Message color={props.color || 'success'} className={className} clearMessage={clearMessage}>
            {props.message || 'Operation completed successfully'}
          </Message>
        </div>
      )
    } else {
      return (
        <div className="fixed top-5 left-1/2 -translate-x-1/2 z-40 md:w-4/5 w-full px-5">
          <Message color={props.color || 'danger'} className={className} clearMessage={clearMessage}>
            {props.message || 'Operation failed'}
          </Message>
        </div>
      )
    }
  } else {
    return <></>
  }
}

export default Notification

import { useUi } from '../../use-ui'

import { type FilePropsCommon, boxSizes } from './index'
import * as UI from '@/ui'

export type InputFileProps = {
  initialValue?: string | number | undefined
} & FilePropsCommon

export const InputFile = ({ name, boxSize, errorMessage, placeholder, initialValue, ...props }: InputFileProps) => {
  const { className } = useUi({
    styles: {
      boxSize: { options: boxSizes, selected: boxSize },
    },
    name: 'Form.File',
    className: `${
      errorMessage && 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 placeholder-red-300'
    }`,
  })

  return (
    <>
      <input
        type="file"
        name={name}
        defaultValue={initialValue}
        placeholder={placeholder}
        className={className}
        {...props}
      />
      {errorMessage && <UI.Form.Error>{errorMessage}</UI.Form.Error>}
    </>
  )
}

InputFile.displayName = 'Form.File.InputFile'

export default InputFile

import { FormikHidden, FormikHiddenProps } from './formik-hidden'
import { InputHidden, InputHiddenProps } from './input-hidden'

export type HiddenProps = HiddenPropsFormik | HiddenPropsInput

export type HiddenPropsCommon = {
  name: string
} & Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'type'>

export type HiddenPropsShared = {
  children?: React.ReactNode
}

export type HiddenPropsFormik = FormikHiddenProps &
  HiddenPropsShared & {
    type?: 'formik'
  }

export type HiddenPropsInput = InputHiddenProps &
  HiddenPropsShared & {
    type?: 'input'
  }

export const Hidden = ({ name, type = 'formik', ...props }: HiddenProps) => (
  <>
    {type === 'formik' && <FormikHidden name={name} {...props} />}
    {type === 'input' && <InputHidden name={name} {...props} />}
  </>
)

Hidden.displayName = 'Form.Hidden'

export default Hidden

import * as React from 'react'
import { useRouter } from 'next/router'
import CircleBlur from '../../public/images/circleBlur.svg?svgr'
import Thumb from '../../public/images/thumb.png'
import Header from '../header'
import Footer from '../footer'
import { ContainerStyles } from '../ui/page'
import { useUi } from '../ui/use-ui'
import { useNotification } from '../ui/use-notification'
import * as UI from '@/ui'

export type BackgroundColour = 'grey' | 'lightGrey' | 'none'

type HeaderButtonProps = {
  label: string
  category: string
  link: string
}

type HeaderSearchButtonProps = {
  label: string
  placeholder: string
  action: () => void
}

export type HeaderProps = {
  title: string
  description: string[]
  buttons?: HeaderButtonProps[]
  searchButton?: HeaderSearchButtonProps
}

export type LayoutProps = {
  children: React.ReactNode
  containerStyle?: ContainerStyles
  backgroundColour?: BackgroundColour
  thumbFooter?: boolean
  header?: HeaderProps
}

export const LearnLayout = ({
  children,
  containerStyle,
  backgroundColour = 'grey',
  thumbFooter = true,
  header,
}: LayoutProps) => {
  const { content } = useNotification()
  const router = useRouter()

  const backgroundColours = {
    grey: 'bg-selphGrey-200',
    lightGrey: 'bg-selphGrey-50',
    none: '',
  }

  const { className } = useUi({
    styles: { layout: { options: backgroundColours, selected: backgroundColour } },
    name: 'Layout',
  })

  return (
    <>
      <div className={`relative flex flex-col min-h-screen overflow-clip ${className}`}>
        <CircleBlur className="absolute w-[800px] h-[800px] md:w-[1500px] md:h-[1500px] top-0 right-0 -translate-y-1/2 translate-x-1/2" />

        <Header />

        {header && (
          <div className="z-10 w-full h-full bg-violet-50 border-b-[3px] pt-8 pb-12">
            <div className="max-w-7xl mx-auto px-4 md:px-6">
              <UI.Block gap="xl">
                <UI.Heading color="midBlue" size={{ default: 'medium', md: 'large' }} weight="regular">
                  <h1>{header.title}</h1>
                </UI.Heading>

                <UI.Block gap="small">
                  {header.description.map((paragraph) => (
                    <UI.Paragraph
                      color="midBlue"
                      size={{ default: 'medium', md: 'large' }}
                      className="font-medium "
                      key={paragraph}
                    >
                      {paragraph}
                    </UI.Paragraph>
                  ))}
                </UI.Block>

                {header.buttons && (
                  <div className="max-w-sm md:max-w-5xl mx-auto gap-5 flex flex-col md:items-center md:flex-row md:justify-between">
                    {header.buttons.map((button) => (
                      <UI.Button
                        key={button.label}
                        type="button"
                        theme="solid"
                        color="pink"
                        size="full"
                        textSize="text-md"
                        onClick={() => router.push(`/learn/${button.category}/${button.link}`)}
                      >
                        {button.label}
                      </UI.Button>
                    ))}
                  </div>
                )}
              </UI.Block>

              <div className="mx-auto self-center">{/* SearchButton "w-3xl" */}</div>
            </div>
          </div>
        )}

        <UI.Page containerStyle={containerStyle}>
          {content && <UI.Notification {...content} className="mb-5" />}

          {thumbFooter && (
            <div className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-[60%] md:translate-y-[60%] lg:translate-y-[65%]">
              <CircleBlur className="relative w-[400px] h-[400px] md:w-[800px] md:h-[800px] bottom-0" />

              <div className="absolute h-56 w-56 sm:w-64 sm:h-64 md:h-96 md:w-96 lg:h-[430px] lg:w-[430px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <UI.Image src={Thumb} fill sizes="(max-width: 768px) 224px, (max-width: 1280px) 256px, 430px" alt="" />
              </div>
            </div>
          )}
          <div className={`relative ${thumbFooter ? 'mb-32' : 'mb-14'}`}>{children}</div>
        </UI.Page>

        <Footer />
      </div>
    </>
  )
}

export default LearnLayout

import { FormikValues } from 'formik/dist/types'
import { type FormikFormProps, FormikForm } from './formik-form'
import { type HtmlFormProps, HtmlForm } from './html-form'

export type FormProps<Value> = FormFormProps | FormFormikProps<Value>

export type FormPropsCommon = unknown

export type FormFormProps = HtmlFormProps & {
  type: 'form'
} & Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLFormElement>, HTMLFormElement>, 'type'>

export type FormFormikProps<Value> = FormikFormProps<Value> & {
  type?: 'formik'
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const Form = function <Value extends FormikValues = FormikValues, ExtraProps = {}>({
  type = 'formik',
  children,
  ...props
}: FormProps<Value> & ExtraProps) {
  return (
    <>
      {
        {
          formik: <FormikForm<Value> {...(props as Omit<FormFormikProps<Value>, 'children'>)}>{children}</FormikForm>,
          form: (
            <HtmlForm {...(props as Omit<FormFormProps, 'children'>)}>{children as FormFormProps['children']}</HtmlForm>
          ),
        }[type]
      }
    </>
  )
}

Form.displayName = 'Form.Form'

export default Form

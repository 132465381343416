import { useUi, type Breakpoints } from './use-ui'

export type GridGaps = 'none' | 'xs' | 'small' | 'medium' | 'large' | 'xl' | 'xxl'
export type GridAligns = 'start' | 'center' | 'end'
export type GridColVerticalAligns = 'top' | 'middle' | 'bottom' | 'stretch'
export type GridSizes = 'flex' | 'flow' | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

export const aligns = {
  start: 'justify-items-start', // Tailwind: sm:justify-items-start  md:justify-items-start  lg:justify-items-start  xl:justify-items-start
  center: 'justify-items-center', // Tailwind: sm:justify-items-center  md:justify-items-center  lg:justify-items-center  xl:justify-items-center
  end: 'justify-items-end', // Tailwind: sm:justify-items-end  md:justify-items-end  lg:justify-items-end  xl:justify-items-end
}

export const colVerticalAligns = {
  top: 'items-start', // Tailwind: sm:items-start  md:items-start  lg:items-start  xl:items-start
  middle: 'items-center', // Tailwind: sm:items-center  md:items-center  lg:items-center  xl:items-center
  bottom: 'items-end', // Tailwind: sm:items-end  md:items-end  lg:items-end  xl:items-end
  stretch: 'items-stretch',
}

export const sizes = {
  default: ``,

  flex: 'flex ', // sm:flex md:flex lg:flex xl:flex
  flow: 'grid grid-flow-col auto-cols-max', // Tailwind: sm:grid-flow-col sm:auto-cols-max  md:grid-flow-col md:auto-cols-max  lg:grid-flow-col lg:auto-cols-max  xl:grid-flow-col xl:auto-cols-max

  0: 'hidden', // Tailwind: sm:grid  md:grid  lg:grid  xl:grid
  1: 'grid grid-cols-1 flex-none', // Tailwind: sm:grid-cols-1  md:grid-cols-1  lg:grid-cols-1  xl:grid-cols-1
  2: 'grid grid-cols-2 flex-none', // Tailwind: sm:grid-cols-2  md:grid-cols-2  lg:grid-cols-2  xl:grid-cols-2
  3: 'grid grid-cols-3 flex-none', // Tailwind: sm:grid-cols-3  md:grid-cols-3  lg:grid-cols-3  xl:grid-cols-3
  4: 'grid grid-cols-4 flex-none', // Tailwind: sm:grid-cols-4  md:grid-cols-4  lg:grid-cols-4  xl:grid-cols-4
  5: 'grid grid-cols-5 flex-none', // Tailwind: sm:grid-cols-5  md:grid-cols-5  lg:grid-cols-5  xl:grid-cols-5
  6: 'grid grid-cols-6 flex-none', // Tailwind: sm:grid-cols-6  md:grid-cols-6  lg:grid-cols-6  xl:grid-cols-6
  7: 'grid grid-cols-7 flex-none', // Tailwind: sm:grid-cols-7  md:grid-cols-7  lg:grid-cols-7  xl:grid-cols-7
  8: 'grid grid-cols-8 flex-none', // Tailwind: sm:grid-cols-8  md:grid-cols-8  lg:grid-cols-8  xl:grid-cols-8
  9: 'grid grid-cols-9 flex-none', // Tailwind: sm:grid-cols-9  md:grid-cols-9  lg:grid-cols-9  xl:grid-cols-9
  10: 'grid grid-cols-10 flex-none', // Tailwind: sm:grid-cols-10  md:grid-cols-10  lg:grid-cols-10  xl:grid-cols-10
  11: 'grid grid-cols-11 flex-none', // Tailwind: sm:grid-cols-11  md:grid-cols-11  lg:grid-cols-11  xl:grid-cols-11
  12: 'grid grid-cols-12 flex-none', // Tailwind: sm:grid-cols-12  md:grid-cols-12  lg:grid-cols-12  xl:grid-cols-12
}

export const gaps = {
  default: '',

  none: '',
  xs: `w-full gap-1`, // Tailwind: sm:gap-1  md:gap-1  lg:gap-1  xl:gap-1
  small: `w-full gap-2`, // Tailwind: sm:gap-2  md:gap-2  lg:gap-2  xl:gap-2
  medium: `w-full gap-5`, // Tailwind: sm:gap-5  md:gap-5  lg:gap-5  xl:gap-5
  large: `w-full gap-6`, // Tailwind: sm:gap-6  md:gap-6  lg:gap-6  xl:gap-6
  xl: `w-full gap-10`, // Tailwind: sm:gap-10  md:gap-10  lg:gap-19  xl:gap-10
  xxl: `w-full gap-16`,
}

export type GridProps = {
  gap?: Breakpoints<GridGaps>
  className?: string
  size?: Breakpoints<GridSizes>
  align?: Breakpoints<GridAligns>
  colVerticalAlign?: Breakpoints<GridColVerticalAligns>
  children: React.ReactNode
}

export const Grid = ({ gap = 'medium', className, size = 1, align, colVerticalAlign, children }: GridProps) => {
  const ui = useUi({
    styles: {
      align: { options: aligns, selected: align },
      gap: { options: gaps, selected: gap },
      sizes: { options: sizes, selected: size },
      colVerticalAlign: { options: colVerticalAligns, selected: colVerticalAlign },
    },
    className,
    name: 'Grid',
  })

  return <div className={ui.className}>{children}</div>
}

Grid.displayName = 'Grid'

export default Grid

import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid'
import 'easymde/dist/easymde.min.css'
import { useField, useFormikContext } from 'formik'
import { FormikValues } from 'formik/dist/types'
import dynamic from 'next/dynamic'
import { useEffect } from 'react'
import { useUi } from '../../use-ui'
import { dependsMatch, ValidateDepends, ValidateDependsValue } from '../validation'
import { boxSizes, type MarkdownPropsCommon } from './index'
import * as UI from '@/ui'

const SimpleMDE = dynamic(() => import('react-simplemde-editor'), { ssr: false })

export type FormikMarkdownProps = {
  depends?: ValidateDepends | ValidateDepends[]
  setIsHidden?: (hidden: boolean) => void
} & MarkdownPropsCommon

export const FormikMarkdown = ({
  name,
  errorMessage,
  boxSize,
  placeholder,
  depends,
  setIsHidden,
  onChange,
  className,
  ...props
}: FormikMarkdownProps) => {
  const { isSubmitting, values } = useFormikContext()
  const [, meta, { setTouched, setValue }] = useField({ name, ...props })

  useEffect(() => {
    if (depends) {
      setIsHidden && setIsHidden(depends && !dependsMatch(depends, values as { [key: string]: ValidateDependsValue }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  useEffect(() => {
    if (isSubmitting) {
      setTouched(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting])

  errorMessage = meta.touched && meta.error ? meta.error : errorMessage

  const ui = useUi({
    styles: {
      boxSize: { options: boxSizes, selected: boxSize },
    },
    name: 'Form.Markdown',
    className: `${
      errorMessage
        ? 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 placeholder-red-300'
        : meta.touched &&
          'focus:ring-green-500 focus:border-green-500 border-green-500 text-green-900 placeholder-green-300'
    } ${className}`,
  })

  return (
    <>
      <div className="relative rounded-md shadow-sm">
        <SimpleMDE
          value={(values as FormikValues)[name]}
          onChange={(v) => {
            setValue(v)
            onChange && onChange(v)
          }}
          placeholder={placeholder}
          className={ui.className}
          {...props}
        />
        {errorMessage && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}

        {meta.touched && !errorMessage && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <CheckCircleIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
          </div>
        )}
      </div>

      {errorMessage && <UI.Form.Error>{errorMessage}</UI.Form.Error>}
    </>
  )
}

FormikMarkdown.displayName = 'Form.Markdown.FormikMarkdown'

export default FormikMarkdown

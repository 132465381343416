import { useEffect, useState } from 'react'
import { useWidth } from './use-width'

export type Breakpoints<T> = BreakpointsSingle<T> | BreakpointsObject<T>
export type BreakpointsSingle<T> = T
export type BreakpointsObject<T> = { [key in Breakpoint]: BreakpointsSingle<T> }

export type Breakpoint = 'default' | 'sm' | 'md' | 'lg' | 'xl' | string
export type BreakpointList = {
  [key: number]: Breakpoint
}

export const useBreakpoint = <Value,>(selected: Breakpoints<Value>) => {
  const { breakpointChain } = useWidth()
  const [value, setValue] = useState<Value>(getBreakpointValue(selected, breakpointChain))

  useEffect(() => {
    setValue(getBreakpointValue(selected, breakpointChain))
  }, [breakpointChain, selected])

  return value
}

export default useBreakpoint

const getBreakpointValue = <Value,>(selected: Breakpoints<Value>, breakpointChain: string[]): Value => {
  // Check if 'selected' is an object and has breakpoint keys
  if (selected && typeof selected === 'object') {
    // Find the first breakpoint in 'selected' that matches an entry in 'breakpointChain'
    const foundBreakpoint = breakpointChain.find((breakpoint) => breakpoint in selected)

    // If a matching breakpoint is found, return its value
    return (selected as BreakpointsObject<Value>)[foundBreakpoint as string]
  } else {
    // If 'selected' is not an object, return it directly as it's assumed to be a single value
    return selected as Value
  }
}

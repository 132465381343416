import { without, pick, forEach } from 'lodash-es'
import { Breakpoints } from './use-breakpoint'

export type UIAttribute = string | number
export { type Breakpoints } from './use-breakpoint'

export type UIStylesOptions = { [key: UIAttribute]: string }

export const useUi = ({
  className = '',
  styles,
}: {
  className?: string
  styles?: {
    [key: string]: {
      options: UIStylesOptions
      selected?: Breakpoints<UIAttribute>
    }
  }
  name: string
}) => {
  const htmlStyles: { [key: string]: UIAttribute[] | { [key: string]: UIAttribute[] | UIAttribute } } = {}

  const parseStyles = ({
    selected,
    options,
    attribute,
    prefix,
  }: {
    selected?: UIAttribute | UIAttribute[]
    options: UIStylesOptions
    attribute: string
    prefix?: string
  }) => {
    if (selected !== undefined) {
      if (!Array.isArray(selected)) {
        selected = [selected]
      }
    } else {
      selected = []
    }

    if ('default' in options) {
      selected = [...selected, 'default']
    }

    selected.forEach((item) => {
      if (!(item in options)) {
        throw new Error(`Unknown style ${item} in attribute ${attribute}`)
      }
    })

    const className = without(Object.values(pick(options, selected)), '')
      .join(' ')
      .split(' ')
      .map((item) => (prefix ? `${prefix}:${item}` : item))
      .join(' ')

    const selectedStyles = without(selected, 'default')

    return { className, selectedStyles }
  }

  let classNames = ''

  if (styles) {
    forEach(styles, (style, attribute) => {
      if (classNames.length > 0) {
        classNames += ' '
      }

      let styles = undefined

      switch (typeof style.selected) {
        case 'object':
          if (!Array.isArray(style.selected)) {
            Object.entries(style.selected).forEach(([key, currentSelected]) => {
              if (classNames.charAt(classNames.length - 1) !== ' ') {
                classNames += ' '
              }

              styles = parseStyles({
                prefix: key === 'default' ? undefined : key,
                selected: currentSelected,
                options: style.options,
                attribute,
              })

              classNames += styles.className
            })

            if (style.selected) {
              htmlStyles[attribute] = style.selected
            }
          }
          break
        default:
          styles = parseStyles({ selected: style.selected, options: style.options, attribute })

          classNames += styles.className

          if (styles.selectedStyles.length > 0) {
            htmlStyles[attribute] = styles.selectedStyles
          }
      }
    })
  }

  return {
    className: `${classNames} ${className}`,
  }
}

export default useUi

import { createContext, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import cookies from 'js-cookie'
import { getHref } from '../routes'
import * as UI from '@/ui'

type ImpersonationContextType = {
  setImpersonateUserId: (userId: string) => void
}

export const ImpersonationContext = createContext<ImpersonationContextType>({
  setImpersonateUserId: () => null,
})

export const ImpersonationProvider = ({ children }: { children: React.ReactNode }) => {
  const { push } = useRouter()
  const [impersonateUserId, setImpersonateUserId] = useState<string>()
  const [isLoaded, setIsLoaded] = useState(false)

  // Load from cookie on first mount
  useEffect(() => {
    const id = cookies.get('impersonateUserId')
    if (id) {
      setImpersonateUserId(String(id))
    }
    setIsLoaded(true)
  }, [])

  // Store in cookie when changed
  useEffect(() => {
    if (!isLoaded) {
      return
    }

    if (impersonateUserId) {
      cookies.set('impersonateUserId', impersonateUserId.toString())
    } else {
      cookies.remove('impersonateUserId')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [impersonateUserId])

  const stopImpersonationSession = (id: string) => {
    push(getHref(['adminCustomer', { criterion: id }]))
    setImpersonateUserId(undefined)
  }

  return (
    <ImpersonationContext.Provider value={{ setImpersonateUserId }}>
      {impersonateUserId && (
        <div className="w-full bg-yellow-300 flex p-4">
          <div className="">
            <h1 className="text-lg font-bold">You are impersonating user ID {impersonateUserId}</h1>
            <ul className="list-disc ml-3">
              <li>This is a diagnostic tool only, not intended for daily operations.</li>
              <li>Think carefully before initiating any actions on behalf of a user.</li>
              <li>
                You may see sensitive information. Stop the impersonation session as soon as you no longer need it.
              </li>
            </ul>
          </div>

          <div className="grow"></div>

          <div className="align-middle">
            <UI.Button theme="basic" color="info" onClick={() => stopImpersonationSession(impersonateUserId)}>
              Stop Impersonating
            </UI.Button>
          </div>
        </div>
      )}

      {children}
    </ImpersonationContext.Provider>
  )
}

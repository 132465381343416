module.exports = {
  extend: {
    transitionProperty: {
      colors: 'background-position',
    },
    boxShadow: {
      tick: '2px 0 0 #06b6d4, 4px 0 0 #06b6d4, 4px -2px 0 #06b6d4, 4px -4px 0 #06b6d4, 4px -6px 0 #06b6d4, 4px -8px 0 #06b6d4;',
    },
    fontFamily: {
      museoSans: ['museo-sans-rounded', 'sans'],
      helvetica: ['Helvetica', 'sans'],
    },
    colors: {
      hotPink: { 500: '#e22e89', 600: '#CF2474', 800: '#B72067' },
      warningYellow: '#f1bc37',
      dangerRed: '#d7592a',
      selphBlue: {
        50: '#d0d9ea',
        100: '#79cbf3',
        200: '#64aad9',
        300: '#4e8ac5',
        400: '#346cad',
        500: '#0d4780',
        600: '#003959',
        700: '#223240',
        800: '#031d26',
      },
      selphGrey: {
        50: '#f4f8fc',
        100: '#e9f0f8',
        200: '#d4dbe4',
        300: '#b9c1cb',
        400: '#a0a9b4',
        500: '#87919d',
        600: '#6d7a85',
        700: '#54626e',
        800: '#3b4a57',
        900: '#223240',
      },
    },
    width: {
      xs: '20rem',
      sm: '24rem',
      md: '28rem',
      lg: '32rem',
      xl: '36rem',
      '2xl': '42rem',
      '3xl': '48rem',
      '4xl': '56rem',
      '5xl': '64rem',
    },
    minWidth: {
      24: '6rem',
      28: '7rem',
      32: '8rem',
      36: '9rem',
    },
  },
  screens: {
    // https://polypane.app/blog/the-breakpoints-we-tested-in-2021-and-the-ones-to-test-in-2022/
    sm: '375px',
    // => @media (min-width: 375px) { ... }
    md: '768px',
    // => @media (min-width: 768px) { ... }
    lg: '1280px',
    // => @media (min-width: 1280px) { ... }
    xl: '1920px',
    // => @media (min-width: 1920px) { ... }
  },
  fontFamily: {
    sans: ['Gill Sans', 'Helvetica', 'ui-sans-serif', 'system-ui'],
  },
}

import { createContext, useContext, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { getParam } from '../../helpers/params'

export type MailchimpContextType = {
  campaignId?: string
  emailId?: string
}

export const MailchimpContext = createContext<MailchimpContextType | undefined>(undefined)

export const MailchimpProvider = ({ children }: { children: React.ReactNode }) => {
  const { query: params, isReady: isRouterReady } = useRouter()

  const [campaignId, setCampaignId] = useState<MailchimpContextType['campaignId']>(undefined)
  const [emailId, setEmailId] = useState<MailchimpContextType['emailId']>(undefined)

  useEffect(() => {
    if (!isRouterReady) return

    setCampaignId(getParam(params.mc_cid) || undefined)
    setEmailId(getParam(params.mc_eid) || undefined)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRouterReady])

  return <MailchimpContext.Provider value={{ campaignId, emailId }}>{children}</MailchimpContext.Provider>
}

export const useMailchimp = () => {
  const context = useContext(MailchimpContext)

  if (context === undefined) {
    throw new Error('useMailchimp must be used within a MailchimpProvider')
  }
  return context
}

export default useMailchimp

import fetch from 'cross-fetch'
import { useCallback, useState } from 'react'
import { PostCodeLookupAddress, PostCodeLookupResult } from '../pages/api/post-code-lookup.api'

export const postCodeLookup = async (postcode: string): Promise<PostCodeLookupAddress[]> => {
  const response = await fetch('/api/post-code-lookup', {
    body: JSON.stringify({ postcode }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })

  if (!response.ok) {
    throw new Error(`No results found. Please check the postcode and try again or enter your address manually.`)
  }

  const body: PostCodeLookupResult = await response.json()

  if (!body.isSuccess) {
    throw new Error(body.message)
  }

  if (body.addresses.length === 0) {
    throw new Error('No results found. Please check the postcode and try again or enter your address manually.')
  }

  return body.addresses
}

export const usePostCodeLookup = () => {
  const [addresses, setAddresses] = useState<PostCodeLookupAddress[] | null>(null)
  const [error, setError] = useState<Error | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const lookupPostcode = useCallback(async (postcode: string) => {
    setAddresses(null)
    setIsLoading(true)
    setError(null)

    try {
      const addresses = await postCodeLookup(postcode)
      setAddresses(addresses)
      setIsLoading(false)
    } catch (error) {
      setError(error as Error)
      setIsLoading(false)
    }
  }, [])

  const clearAddresses = useCallback(() => {
    setAddresses(null)
    setError(null)
  }, [])

  return {
    addresses,
    error,
    isLoading,
    lookupPostcode,
    clearAddresses,
  }
}

export default postCodeLookup

import { useField, useFormikContext } from 'formik'
import { map } from 'lodash-es'
import { Field } from 'formik'
import cx from 'classnames'
import { useEffect } from 'react'
import { FormikValues } from 'formik/dist/types'
import { dependsMatch, ValidateDepends, ValidateDependsValue, dependsParseValue } from '../validation'
import { useUi } from '../../use-ui'
import { type RadioPropsCommon } from './index'
import * as UI from '@/ui'

export type FormikRadioGroupProps = {
  optionStyle?: 'button' | 'radio' | 'range'
  depends?: ValidateDepends | ValidateDepends[]
  setIsHidden?: (hidden: boolean) => void
} & RadioPropsCommon

export const FormikRadioGroup = ({
  name,
  options,
  errorMessage,
  gridGap,
  gridSize,
  gridAlign,
  gridColVerticalAlign,
  depends,
  setIsHidden,
  optionStyle = 'radio',
  ...props
}: FormikRadioGroupProps) => {
  const { isSubmitting, values } = useFormikContext()
  const [, meta, { setTouched }] = useField({ name })

  options = Array.isArray(options) ? Object.assign({}, ...options.map((v) => ({ [v]: v }))) : options

  useEffect(() => {
    if (depends) {
      setIsHidden && setIsHidden(depends && !dependsMatch(depends, values as { [key: string]: ValidateDependsValue }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  errorMessage = meta.touched && meta.error ? meta.error : errorMessage

  useEffect(() => {
    if (isSubmitting) {
      setTouched(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting])

  const { className } = useUi({
    name: 'Form.RadioGroup',
    className: cx(
      errorMessage
        ? 'ring-red-500 text-red-600 border-red-500'
        : meta.touched
        ? 'ring-green-500 text-green-600 border-green-300'
        : 'text-indigo-600 border-gray-300',
      props.readOnly && 'cursor-not-allowed bg-selphGrey-100',
      'focus:ring-indigo-500 h-4 w-4',
    ),
  })

  const dotProps = (selected: boolean) => ({
    className:
      `${
        selected ? (props?.readOnly ? 'bg-gray-200' : 'bg-white') : props?.readOnly ? 'bg-gray-300' : 'bg-gray-200'
      } h-[20px] w-[20px]  rounded-[50%] relative ` +
      `${
        selected ? 'before:opacity-100 before:scale-100 ' : 'before:opacity-0 before:scale-150'
      } before:absolute before:content-[""] before:top-[4px] before:left-[4px] before:w-[12px] before:h-[12px] ${
        props?.readOnly ? 'before:bg-selphBlue-200' : 'before:bg-selphBlue-200'
      } before:rounded-[50%] `,
  })

  const labelProps = (selected: boolean) => ({
    className: cx(
      selected
        ? props?.readOnly
          ? 'bg-selphBlue-200 border-selphBlue-200 cursor-not-allowed'
          : 'bg-selphBlue-200 border-selphBlue-200 cursor-pointer'
        : errorMessage
        ? 'bg-gray-50 border-red-400'
        : props?.readOnly
        ? 'bg-selphGrey-100 border-gray-400 cursor-not-allowed'
        : 'bg-gray-50 border-gray-400 cursor-pointer',
      'flex w-fit items-center justify-evenly m-0 mr-3 mb-3 rounded-md  py-2 px-3 border',
    ),
  })

  const spanProps = (selected: boolean) => ({
    className: `${selected ? 'text-white' : 'text-gray-500'} pl-2 text-sm`,
  })

  return (
    <UI.Block gap="xs">
      {
        {
          radio: (
            <UI.Grid gap={gridGap} size={gridSize} align={gridAlign} colVerticalAlign={gridColVerticalAlign}>
              {map(options, (value, label) => {
                return (
                  <div key={value + label} className={gridSize === 'flex' ? 'mr-5' : ''}>
                    <UI.Form.Label type="option" text={label}>
                      <Field type="radio" name={name} value={`${value}`} {...props} className={className} />
                    </UI.Form.Label>
                  </div>
                )
              })}
            </UI.Grid>
          ),
          button: (
            <div className="-mb-3 -mr-3">
              {map(options, (value, label) => {
                const selected = (values as FormikValues)[name] === dependsParseValue(value)
                return (
                  <div className="inline-block" key={value + label}>
                    <label {...labelProps(selected)}>
                      {!props.readOnly && (
                        <Field type="radio" name={name} value={`${value}`} className="hidden" {...props} />
                      )}
                      <div {...dotProps(selected)}></div>
                      <span {...spanProps(selected)}>{label}</span>
                    </label>
                  </div>
                )
              })}
            </div>
          ),
          range: (
            <UI.Grid
              size="flow"
              gap="none"
              className={`${
                errorMessage ? 'border-red-400' : 'border-gray-400'
              } border py-[8px] w-fit rounded-lg overflow-hidden`}
            >
              {map(options, (value, label) => {
                const selected = (values as FormikValues)[name] === dependsParseValue(value)
                return (
                  <div className="inline-block border-r last:border-0" key={value + label}>
                    <label
                      className={`${
                        selected ? 'bg-selphBlue-200 text-white' : `bg-white text-gray-500`
                      } px-5 py-3  cursor-pointer`}
                    >
                      <Field type="radio" name={name} value={`${value}`} className="hidden" />
                      <span className="text-sm">{label}</span>
                    </label>
                  </div>
                )
              })}
            </UI.Grid>
          ),
        }[optionStyle]
      }

      {errorMessage && <UI.Form.Error>{errorMessage}</UI.Form.Error>}
    </UI.Block>
  )
}

FormikRadioGroup.displayName = 'Form.RadioGroup.FormikRadioGroup'

export default FormikRadioGroup

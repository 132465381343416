/**
 * Takes a param and returns it as a string
 * @param param
 * @returns
 */
export const getParam = (param: string | string[] | undefined) => {
  if (Array.isArray(param)) {
    return param[0]
  }

  return param ?? ''
}

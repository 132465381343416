// eslint-disable-next-line no-restricted-imports
import { default as NextImage, ImageProps as NextImageProps } from 'next/image'
import { useUi } from './use-ui'

export type ImageProps = Omit<NextImageProps, 'width' | 'height'> & {
  blurImage?: string
  blurImageJson?: { [key: string]: any }
  blurImageJsonFolder?: string

  containerClassName?: string
  responsive?: boolean | number
  width?: NextImageProps['width'] | 'auto'
  height?: NextImageProps['width'] | 'auto'
}

export const Image = ({
  children,
  responsive,
  blurImage,
  blurImageJson,
  blurImageJsonFolder,
  containerClassName,
  width,
  height,
  className,
  ...props
}: ImageProps) => {
  const containers = {
    default: `${!width && !height ? 'responsive-image' : 'inline-block'}`,
  }

  const containerUi = useUi({
    styles: {
      container: { options: containers },
    },
    name: 'Image',
    className: containerClassName,
  })

  if (!blurImage && blurImageJson && typeof props.src === 'string') {
    let key = props.src

    if (blurImageJsonFolder && props.src.includes(blurImageJsonFolder, 0)) {
      key = props.src.replace(blurImageJsonFolder, '').replace(/\//g, '.')
    }

    if (blurImageJson[key]) {
      blurImage = blurImageJson[key]
    }
  }

  if (responsive) {
    return (
      <span className={containerUi.className}>
        <NextImage
          {...props}
          placeholder={blurImage ? 'blur' : undefined}
          width={width as NextImageProps['width'] | 100}
          height={height as NextImageProps['height'] | 100}
          fill
          blurDataURL={blurImage}
        >
          {children}
        </NextImage>
      </span>
    )
  } else if (blurImage) {
    return (
      <NextImage
        {...props}
        className={className}
        placeholder={blurImage ? 'blur' : undefined}
        width={width as NextImageProps['width']}
        height={height as NextImageProps['height']}
        blurDataURL={blurImage}
      >
        {children}
      </NextImage>
    )
  } else {
    return (
      <NextImage
        {...props}
        width={width as NextImageProps['width']}
        height={height as NextImageProps['height']}
        className={className}
      >
        {children}
      </NextImage>
    )
  }
}

Image.displayName = 'Image'

export default Image

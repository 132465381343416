import { PopoverButton, PopoverPanel } from '@headlessui/react'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useUi } from '../../use-ui'
import { getHref } from '../../../../routes'
import { colors, gaps, NavItemProps } from './index'

export type InnerProps = {
  open: boolean
  close: () => void
} & NavItemProps

export const Inner = ({
  label,
  icon,
  gap,
  color = 'orange',
  open,
  close,
  onOpen,
  onClose,
  onClick,
  to,
  children,
}: InnerProps) => {
  const { replace: redirect } = useRouter()

  const Icon = icon
  const [isOpened, setIsOpened] = useState<boolean>(false)

  useEffect(() => {
    if (open) {
      setIsOpened(true)
    } else {
      setIsOpened(false)
    }

    open ? onOpen && onOpen() : isOpened && onClose && onClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const panel = useUi({
    styles: {
      gaps: { options: gaps, selected: gap },
    },
    name: 'Navbar.NavItem',
    className: `${colors[color]} absolute min-w-[200px] p-3 z-10`,
  })

  onClick = onClick ? onClick : to ? () => redirect(getHref(to)) : undefined

  return (
    <>
      <PopoverButton
        as="div"
        onClick={onClick}
        className={`${open ? `${colors[color]}` : ''} text-white hover:${
          colors[color]
        } focus-visible:outline-0 inline-block flex items-center p-3 cursor-pointer`}
      >
        <div>
          {Icon && <Icon className={`inline-block w-5 h-5 ${label ? 'mr-2' : ''}`} />}
          {label}
        </div>
      </PopoverButton>

      {children && (
        <div className="float-left">
          <PopoverPanel className={panel.className}>
            {typeof children === 'function' ? children({ open, close }) : children}
          </PopoverPanel>
        </div>
      )}
    </>
  )
}

export default Inner

import { map } from 'lodash-es'
import { useUi } from '../../use-ui'
import { type RadioPropsCommon } from './index'
import * as UI from '@/ui'

export type InputRadioGroupProps = RadioPropsCommon

export const InputRadioGroup = ({
  name,
  options,
  gridGap,
  gridSize,
  gridAlign,
  errorMessage,
  gridColVerticalAlign,
  readOnly,
  checkedValue,
  ...props
}: InputRadioGroupProps) => {
  const { className } = useUi({
    name: 'Form.RadioGroup',
  })

  return (
    <UI.Block gap="xs">
      <UI.Grid gap={gridGap} size={gridSize} align={gridAlign} colVerticalAlign={gridColVerticalAlign}>
        {map(options, (value, label) => (
          <div key={value + label} className={gridSize === 'flex' ? 'mr-5' : ''}>
            <UI.Form.Label type="option" text={label}>
              <input
                type="radio"
                name={name}
                value={value as string}
                readOnly={readOnly === 'readOnly'}
                checked={value === checkedValue}
                className={className}
                {...props}
              />
            </UI.Form.Label>
          </div>
        ))}
      </UI.Grid>

      {errorMessage && <UI.Form.Error>{errorMessage}</UI.Form.Error>}
    </UI.Block>
  )
}

InputRadioGroup.displayName = 'Form.RadioGroup.InputRadioGroup'

export default InputRadioGroup

import { createContext, useState, useContext, useEffect, useCallback, useMemo } from 'react'
import { useRouter } from 'next/router'

export type Notification = {
  success?: boolean
  message?: string | null
  color?: 'success' | 'danger' | 'warning' | 'info'
}

export type NotificationContextType = {
  clearMessage: () => void
  showMutationResponse: (response: { success: boolean; message: string | null | undefined }) => void
  showQueryResponse: (response: { success: boolean; message: string | null | undefined }) => void
  showNotification: (notification: Notification) => void
  showMessage: ({ message, color }: { message: string | null | undefined; color: Notification['color'] }) => void
  content: Notification | null
}

export const NotificationContext = createContext<NotificationContextType | null>(null)

export const NotificationProvider = ({ children }: { children: React.ReactNode }) => {
  const { pathname } = useRouter()
  const [notification, setNotification] = useState<Notification | null>(null)

  const clearMessage = useCallback(() => {
    setNotification(null)
  }, [])

  useEffect(() => {
    clearMessage()
  }, [pathname, clearMessage])

  const showMutationResponse = useCallback((response: Notification) => {
    setNotification({ success: response.success, message: response.message })
  }, [])

  const showQueryResponse = useCallback((response: Notification) => {
    setNotification({ success: response.success, message: response.message })
  }, [])

  const showNotification = useCallback((notification: Notification) => {
    setNotification({ success: notification.success, message: notification.message })
  }, [])

  const showMessage = useCallback(({ message, color }: Parameters<NotificationContextType['showMessage']>[0]) => {
    setNotification({ message, color })
  }, [])

  const value = useMemo(
    () => ({
      clearMessage,
      showMutationResponse,
      showQueryResponse,
      showNotification,
      showMessage,
      content: notification,
    }),
    [clearMessage, notification, showMutationResponse, showQueryResponse, showNotification, showMessage],
  )

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>
}

export const useNotification = () => {
  const context = useContext<NotificationContextType | null>(NotificationContext)

  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider')
  }

  return context
}

// import { type Breakpoints } from '../../use-ui'
import { useState } from 'react'
import { Label } from '../label'
import { HelpText } from '../help-text'
import { FormikSelect, type FormikSelectProps } from './formik-select'
import { InputSelect, type InputSelectProps } from './input-select'
import * as UI from '@/ui'

export type SelectProps = SelectPropsFormik | SelectPropsInput

export type SelectPropsCommon = {
  name: string
  multiselect?: boolean
  options: { [key: string | number]: string | number | boolean | null | undefined }
  errorMessage?: string
}

export type SelectPropsShared = {
  helpTextAbove?: string
  helpTextBelow?: string

  children?: React.ReactNode
}

export type SelectPropsFormik = SelectPropsShared &
  FormikSelectProps & {
    type?: 'formik'
  }

export type SelectPropsInput = SelectPropsShared &
  InputSelectProps & {
    type?: 'input'
    depends?: never
    optionStyle?: never
  }

export const Select = ({ name, type = 'formik', children, helpTextAbove, helpTextBelow, ...props }: SelectProps) => {
  const [isHidden, setIsHidden] = useState<boolean>(false)

  return (
    <div className={isHidden ? 'hidden' : ''}>
      {children && <Label htmlFor={type === 'formik' ? `input-${name}` : name}>{children}</Label>}

      <UI.Block gap="xs">
        {helpTextAbove && <HelpText>{helpTextAbove}</HelpText>}

        {type === 'formik' ? (
          <FormikSelect
            name={name}
            setIsHidden={setIsHidden}
            {...(props as Omit<
              SelectPropsFormik,
              'name' | 'gridGap' | 'gridSize' | 'gridAlign' | 'gridColVerticalAlign' | 'setIsHidden'
            >)}
          />
        ) : (
          <InputSelect name={name} {...props} />
        )}

        {helpTextBelow && <HelpText>{helpTextBelow}</HelpText>}
      </UI.Block>
    </div>
  )
}

Select.displayName = 'Form.Select'

export default Select

import { Popover } from '@headlessui/react'
import * as React from 'react'
import { useUi } from '../../use-ui'
import { RoutesLink } from '../../../../routes'
import Inner from './inner'

export type NavItemProps = {
  label?: React.ReactNode | string
  color?: 'purple' | 'blue' | 'green' | 'orange' | 'red' | 'yellow'
  icon?: React.FC<React.ComponentProps<'svg'>>
  onOpen?: () => void
  onClose?: () => void
  onClick?: () => void
  gap?: 'small' | 'medium' | 'large' | 'xl'
  to?: RoutesLink
  className?: string
  children?: React.ReactNode | string | (({ open, close }: { open: boolean; close: () => void }) => React.ReactNode)
}

export const colors = {
  purple: 'bg-purple-800', // Tailwind: hover:bg-purple-800
  blue: 'bg-cyan-700', // Tailwind: hover:bg-cyan-700
  green: 'bg-teal-700', // Tailwind: hover:bg-teal-700
  orange: 'bg-orange-700', // Tailwind: hover:bg-orange-700
  red: 'bg-red-700', // Tailwind: hover:bg-red-700
  yellow: 'bg-yellow-700', // Tailwind: hover:bg-yellow-500
}

export const gaps = {
  small: 'p-3',
  medium: 'p-5',
  large: 'p-6',
  xl: 'p-10',
}

export const NavItem = ({ color = 'orange', gap = 'small', children, className, ...props }: NavItemProps) => {
  const nav = useUi({
    name: 'Navbar.NavItem',
    className: `inline-block focus-visible:outline-0 ${className}`,
  })

  return (
    <Popover as="div" className={nav.className}>
      {({ open, close }) => (
        <Inner color={color} gap={gap} open={open} close={close} {...props}>
          {children}
        </Inner>
      )}
    </Popover>
  )
}

NavItem.displayName = 'Navbar.NavItem'

export default NavItem

import { useUi, type Breakpoints } from './use-ui'

export type HeadingSizes = 'xxs' | 'xs' | 'small' | 'medium' | 'large' | 'xl'
export type HeadingColors = 'darkBlue' | 'lightBlue' | 'midBlue' | 'white' | 'electric' | 'hotPink'
export type HeadingWeights = 'thin' | 'semiBold' | 'regular'
export type HeadingFaces = 'sans' | 'museo'
export type HeadingProps = {
  size?: Breakpoints<HeadingSizes>
  weight?: Breakpoints<HeadingWeights>
  face?: Breakpoints<HeadingFaces>
  color?: Breakpoints<HeadingColors>
  className?: string
  children: React.ReactNode
}

export const Heading = ({
  className,
  color = 'darkBlue',
  weight = 'semiBold',
  face = 'sans',
  size = 'medium',
  children,
}: HeadingProps) => {
  const colors = {
    white: 'text-white',
    lightBlue: 'text-selphBlue-400',
    midBlue: 'text-selphBlue-500',
    darkBlue: 'text-selphBlue-600',
    hotPink: 'text-hotPink-500',
    electric: 'text-transparent bg-clip-text electric-blue-gradient',
  }

  const weights = {
    thin: 'font-thin',
    regular: 'font-normal',
    semiBold: 'font-semibold',
  }

  const faces = {
    sans: 'font-sans',
    museo: 'font-museoSans',
  }

  const sizes = {
    default: 'block',
    xxs: 'text-xl', // Tailwind: sm:text-xl  md:text-xl  lg:text-xl  xl:text-xl
    xs: 'text-2xl', // Tailwind: sm:text-2xl  md:text-2xl  lg:text-2xl  xl:text-2xl
    small: 'text-3xl', // Tailwind: sm:text-3xl  md:text-3xl  lg:text-3xl  xl:text-3xl
    medium: 'text-4xl', // Tailwind: sm:text-4xl  md:text-4xl  lg:text-4xl  xl:text-4xl
    large: 'text-5xl', // Tailwind: sm:text-5xl  md:text-5xl  lg:text-5xl  xl:text-5xl
    xl: 'leading-none text-7xl', // Tailwind: sm:text-7xl sm:leading-none  md:text-7xl md:leading-none  lg:text-7xl lg:leading-none x l:text-7xl xl:leading-none
  }

  const ui = useUi({
    styles: {
      sizes: { options: sizes, selected: size },
      colors: { options: colors, selected: color },
      weights: { options: weights, selected: weight },
      faces: { options: faces, selected: face },
    },
    name: 'Heading',
    className,
  })

  return <div className={ui.className}>{children}</div>
}

Heading.displayName = 'Heading'

export default Heading

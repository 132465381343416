export type LabelProps = LabelPropsDefault | LabelPropsOption

export type LabelPropsShared = {
  children: React.ReactNode
}

export type LabelPropsDefault = {
  type?: 'default'
  htmlFor: string
  text?: never
} & LabelPropsShared

export type LabelPropsOption = {
  type?: 'option'
  htmlFor?: never
  text: string
} & LabelPropsShared

export const Label = ({ type = 'default', text, htmlFor, children }: LabelProps) => (
  <>
    {type === 'default' && (
      <label htmlFor={htmlFor} className="block text-base font-medium text-gray-700 pb-1">
        {children}
      </label>
    )}
    {type === 'option' && (
      <label className="flex align-center">
        <div>{children}</div>
        <div className="ml-3 mt-1 text-sm font-medium text-gray-700">{text}</div>
      </label>
    )}
  </>
)

Label.displayName = 'Form.Label'

export default Label

import { useState } from 'react'
import { SimpleMDEReactProps } from 'react-simplemde-editor/src/SimpleMdeReact'
import { type Breakpoints, useUi } from '../../use-ui'

import { Label } from '../label'
import { HelpText } from '../help-text'
import { InputMarkdown, type InputMarkdownProps } from './input-markdown'
import { FormikMarkdown, type FormikMarkdownProps } from './formik-markdown'
import * as UI from '@/ui'

export type MarkdownSize = 'medium'

export type MarkdownProps = MarkdownPropsFormik | MarkdownPropsInput

export type MarkdownPropsCommon = {
  name: string
  placeholder?: string
  errorMessage?: string
  theme?: 'light' | 'dark'
  height?: 'small' | 'medium' | 'large'
  className?: string

  boxSize?: Breakpoints<MarkdownSize>
} & Omit<SimpleMDEReactProps, 'name' | 'placeholder' | 'className'>

export type MarkdownPropsShared = {
  helpTextAbove?: string
  helpTextBelow?: string

  children?: React.ReactNode
}

export type MarkdownPropsFormik = FormikMarkdownProps &
  MarkdownPropsShared & {
    type?: 'formik'
  }

export type MarkdownPropsInput = InputMarkdownProps &
  MarkdownPropsShared & {
    type?: 'input'
  }

export const boxSizes = {
  default: `block w-full focus:outline-none sm:text-sm rounded-md`,
  medium: '',
}

export const Markdown = ({
  name,
  type = 'formik',
  helpTextAbove,
  helpTextBelow,
  height = 'medium',
  className,
  children,
  ...props
}: MarkdownProps) => {
  const [isHidden, setIsHidden] = useState<boolean>(false)

  const heights = {
    none: '',
    small: 'min-h-[4rem]',
    medium: 'min-h-[8rem]',
    large: 'min-h-[12rem]',
  }

  const ui = useUi({
    styles: {
      heights: { options: heights, selected: height },
    },
    name: 'Form.Markdown',
    className: className,
  })

  return (
    <div className={isHidden ? 'hidden' : ''}>
      {children && <Label htmlFor={type === 'formik' ? `input-${name}` : name}>{children}</Label>}

      <UI.Block gap="xs">
        {helpTextAbove && <HelpText className="-mb-1">{helpTextAbove}</HelpText>}

        {type === 'formik' && (
          <FormikMarkdown name={name} setIsHidden={setIsHidden} className={ui.className} {...props} />
        )}
        {type === 'input' && <InputMarkdown name={name} className={ui.className} {...props} />}

        {helpTextBelow && <HelpText>{helpTextBelow}</HelpText>}
      </UI.Block>
    </div>
  )
}

Markdown.displayName = 'Form.Markdown'

export default Markdown

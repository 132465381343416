import { gql } from '@apollo/client'
import { signOut, useSession } from 'next-auth/react'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { getItem, removeItem, setItem, ValueType } from '../use-storage'
import { BiologicalSex, useUserInfoQuery } from '../../gql'
import * as UI from '@/ui'

export type UserType = UserTypeNew | UserTypeAccount

export type UserTypeShared = {
  authenticated?: boolean
  cognitoId?: string
  emailAddress: string
  deliveryAddress?: UI.Form.AddressValues
  biologicalSex?: BiologicalSex | null
  phoneNumber?: string | null
  dob?: string | null
  groups?: string[]
  isAdmin?: boolean
  isGuest?: boolean
  isLoggedIn?: boolean
  isCustomer?: boolean
}

export type UserTypeNew = {
  newUser?: boolean
  firstName?: string | null
  lastName?: string | null
  friendlyName?: string | null
} & UserTypeShared

export type UserTypeAccount = {
  newUser?: never
  firstName?: string | null
  lastName?: string | null
  friendlyName?: string | null
} & UserTypeShared

export type CreateProps = { emailAddress: UserType['emailAddress'] }
export type UpdateUserProps = {
  firstName: UserType['firstName']
  lastName: UserType['lastName']
  biologicalSex: UserType['biologicalSex']
  phoneNumber: UserType['phoneNumber']
  deliveryAddress: UserType['deliveryAddress']
  newEmailAddress?: UserType['emailAddress'] | undefined
  emailAddressConfirm?: UserType['emailAddress'] | undefined
  newPassword?: string | undefined
  confirmPassword?: string | undefined
}

export type UseUserResult = {
  create({ emailAddress }: CreateProps): void
  clear(): void
  currentUser(): UserType | undefined
  logout: () => Promise<void>
  isLoggedIn: boolean
  isReady: boolean
  user: UserType | undefined
}

const createSessionStorage = (key: string, cls?: any) => {
  return {
    get<Value extends ValueType = ValueType>(): Value | undefined {
      const sessionData = getItem<Value>(key, 'session')
      if (!sessionData) return undefined
      return cls ? new cls(sessionData) : sessionData
    },
    set(value: any) {
      if (value === undefined) {
        removeItem(key, 'session')
      } else {
        setItem(key, value, 'session')
      }
    },
  }
}

const guestUserStorage = createSessionStorage('guestUser')
const serializedGuest = guestUserStorage.get()

export type CurrentUserContextType = UseUserResult
export const CurrentUserContext = createContext<CurrentUserContextType | undefined>(undefined)
export const CurrentUserProvider = ({ children }: { children: React.ReactNode }) => {
  const { data: sessionData, status } = useSession()
  const client = useApolloClient()

  const [user, setUser] = useState<UserType | undefined>(undefined)
  const [guest, setGuest] = useState<UserType | undefined>(serializedGuest ? createUser(serializedGuest) : undefined)
  const [isTokenSet, setIsTokenSet] = useState(false)

  useEffect(() => {
    const currentToken = window.sessionStorage.getItem('idToken')

    if (typeof window !== 'undefined' && status === 'authenticated' && currentToken !== sessionData?.idToken) {
      sessionData?.idToken && window.localStorage.setItem('idToken', sessionData?.idToken)
      sessionData?.accessToken && window.localStorage.setItem('accessToken', sessionData?.accessToken)

      setIsTokenSet(true)
    }

    if (typeof window !== 'undefined' && status === 'unauthenticated') {
      window.localStorage.removeItem('idToken')
      window.localStorage.removeItem('accessToken')

      setIsTokenSet(false)
    }
  }, [sessionData?.idToken, sessionData?.accessToken, status])

  const { data: userData, loading } = useUserInfoQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: !isTokenSet,
  })

  useEffect(() => {
    if (sessionData?.user?.email) {
      guestUserStorage.set(undefined)
      const userInfo = userData?.viewer?.user

      setUser(
        createUser({
          emailAddress: sessionData.user.email,
          authenticated: true,
          isAdmin: sessionData.user.isAdmin,
          firstName: userInfo?.firstName,
          lastName: userInfo?.lastName,
          friendlyName: userInfo?.friendlyName,
          dob: userInfo?.dob,
          biologicalSex: userInfo?.biologicalSex,
          phoneNumber: userInfo?.phoneNumber,
        }),
      )
    } else if (guest) {
      setUser(createUser(guest))
    } else {
      setUser(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionData?.user?.email, guest, userData?.viewer?.user])

  function createUser(userData: UserType): UserType {
    return {
      ...userData,
      isGuest: (!userData.authenticated && userData.newUser) || false,
      isLoggedIn: (userData.authenticated && !userData.newUser) || false,
      isCustomer: !userData.isAdmin,
    }
  }

  const createGuest = useCallback(({ emailAddress }: CreateProps) => {
    const guest = createUser({ emailAddress, newUser: true })
    guestUserStorage.set(guest)
    setGuest(guest)
  }, [])

  const clearGuest = useCallback(() => {
    guestUserStorage.set(undefined)
    setGuest(undefined)
  }, [])

  const currentUser = useCallback(() => {
    return user
  }, [user])

  const logout = useCallback(async () => {
    client.stop()
    await client.resetStore()
    await signOut({ redirect: false })

    window.sessionStorage.removeItem('checkout')
    window.sessionStorage.removeItem('idToken')
    window.sessionStorage.removeItem('accessToken')

    setIsTokenSet(false)
  }, [client])

  const value = {
    create: createGuest,
    clear: clearGuest,
    isLoggedIn: user?.isLoggedIn || false,
    user,
    isReady: status !== 'loading' && !loading,
    logout,
    currentUser,
  }

  return <CurrentUserContext.Provider value={value}>{children}</CurrentUserContext.Provider>
}

export const useUser = () => {
  const context = useContext(CurrentUserContext)
  if (context === undefined) {
    throw new Error('useUser must be used within a CurrentUserProvider')
  }
  return context
}

export default useUser

gql`
  query UserInfo {
    viewer {
      user {
        email
        firstName
        lastName
        friendlyName
        dob
        phoneNumber
        biologicalSex
      }
    }
  }
`

export { Authenticated } from './authenticated'

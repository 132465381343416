// import { type Breakpoints } from '../../use-ui'
import { useState } from 'react'
import { Label } from '../label'
import { HelpText } from '../help-text'
import { FormikCheckbox, type FormikCheckboxProps } from './formik-checkbox'
import { InputCheckbox, type InputCheckboxProps } from './input-checkbox'
import * as UI from '@/ui'

export type CheckboxProps = CheckboxPropsFormik | CheckboxPropsInput

export type CheckboxPropsCommon = {
  name: string
  errorMessage?: string
} & Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'type' | 'readOnly'>

export type CheckboxPropsShared = {
  helpTextAbove?: string
  helpTextBelow?: string
  handleChange?: () => void

  children?: React.ReactNode
}

export type CheckboxPropsFormik = CheckboxPropsShared &
  FormikCheckboxProps & {
    type?: 'formik'
  }

export type CheckboxPropsInput = CheckboxPropsShared &
  InputCheckboxProps & {
    type?: 'input'
    depends?: never
    setIsHidden?: never
    optionStyle?: never
  }

export const Checkbox = ({
  name,
  type = 'formik',
  children,
  helpTextAbove,
  helpTextBelow,
  ...props
}: CheckboxProps) => {
  const [isHidden, setIsHidden] = useState<boolean>(false)

  return (
    <div className={isHidden ? 'hidden' : ''}>
      {children && <Label htmlFor={type === 'formik' ? `input-${name}` : name}>{children}</Label>}

      <UI.Block gap="xs" className="pt-1">
        {helpTextAbove && <HelpText>{helpTextAbove}</HelpText>}

        {type === 'formik' ? (
          <FormikCheckbox name={name} setIsHidden={setIsHidden} {...(props as Omit<CheckboxPropsFormik, 'name'>)} />
        ) : (
          <InputCheckbox name={name} {...props} />
        )}

        {helpTextBelow && <HelpText>{helpTextBelow}</HelpText>}
      </UI.Block>
    </div>
  )
}

Checkbox.displayName = 'Form.Checkbox'

export default Checkbox

import { useFormikContext } from 'formik'
import * as UI from '@/ui'

export const Errors = ({ message, isClicked }: { message?: React.ReactNode; isClicked?: boolean }) => {
  const formik = useFormikContext()

  isClicked = isClicked === undefined ? true : isClicked

  const numberOfErrors = formik && isClicked ? Object.keys(formik.errors).length : 0

  return (
    <>
      {numberOfErrors > 0 && (
        <UI.Form.Error>{`${numberOfErrors} field(s) have errors, please check and try again.`}</UI.Form.Error>
      )}
      {message && <UI.Form.Error>{message}</UI.Form.Error>}
    </>
  )
}

export default Errors

import * as chrono from 'chrono-node'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid'
import { Field, useField, useFormikContext } from 'formik'
import cx from 'classnames'
import { importDate, now } from '../../../../helpers/date'
import useUi from '../../use-ui'
import { DatePickerPropsCommon, boxSizes } from '.'
import * as UI from '@/ui'

export type FormikDatePickerProps = DatePickerPropsCommon

const FormikDatePicker = ({
  name,
  label,
  field,
  dobFormatter,
  onKeyUp,
  errorMessage,
  boxSize,
  placeholder,
  ...props
}: FormikDatePickerProps) => {
  const { isSubmitting } = useFormikContext()
  const [fieldProps, meta, { setTouched, setValue }] = useField({ name })
  const [dateString, setDateString] = useState<string>(fieldProps.value || '')

  useEffect(() => {
    if (isSubmitting) {
      setTouched(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting])

  useEffect(() => {
    const date = chrono.en.GB.parseDate(dateString)

    if (date) {
      setValue(date.toISOString())
    } else {
      setValue(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateString])

  const yearsOld = useMemo(() => {
    const age = calculateAge(dateString)
    const msg = `${age} ${age === 1 ? 'year' : 'years'}`

    return msg
  }, [dateString])

  errorMessage = meta.touched && meta.error ? meta.error : errorMessage

  const { className } = useUi({
    styles: {
      boxSize: { options: boxSizes, selected: boxSize },
    },
    name: 'Form.DatePicker',
    className: cx(
      errorMessage && 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 placeholder-red-300',
      meta.touched &&
        'focus:ring-green-500 focus:border-green-500 border-green-500 text-green-900 placeholder-green-300',
      props?.readOnly && 'cursor-not-allowed bg-selphGrey-100',
      `${props.className} focus:ring-selphBlue-300 focus:border-selphBlue-300`,
    ),
  })

  if (!fieldProps) {
    return <p>Loading...</p>
  }

  const keyup = (e: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyUp && onKeyUp(e)
  }

  return (
    <>
      <div className="relative rounded-md z-10">
        <div className="flex">
          {label && <label className="label text-[0.825rem] font-light">{label}</label>}

          <Field
            type="text"
            name={name}
            placeholder={placeholder}
            value={dateString}
            onKeyUp={keyup}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setDateString(e.target.value)}
            {...props}
            className={className}
          />

          {errorMessage && (
            <ExclamationCircleIcon
              className="absolute right-3 top-2.5 h-5 w-5 text-red-500 pointer-events-none"
              aria-hidden="true"
            />
          )}

          {meta.touched && !errorMessage && (
            <CheckCircleIcon
              className="absolute right-3 top-2.5 h-5 w-5 text-green-500 pointer-events-none"
              aria-hidden="true"
            />
          )}
        </div>

        {fieldProps.value && (
          <p className="mt-1 text-sm text-green-600">
            {field === 'date' && (
              <>
                {chrono.en.GB.parseDate(dateString)?.toLocaleDateString(undefined, {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </>
            )}

            {field === 'dateOfBirth' && (
              <>
                {!errorMessage && (
                  <>
                    {dobFormatter ? (
                      dobFormatter(yearsOld)
                    ) : (
                      <span className="ml-3">
                        You are <strong>{yearsOld}</strong>
                        {' old, born on: '}
                      </span>
                    )}
                  </>
                )}

                <strong>
                  {chrono.en.GB.parseDate(dateString)?.toLocaleDateString(undefined, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </strong>
              </>
            )}
          </p>
        )}
      </div>

      {errorMessage && <UI.Form.Error>{errorMessage}</UI.Form.Error>}
    </>
  )
}

FormikDatePicker.displayName = 'Form.Date.FormikDatePicker'

export default FormikDatePicker

export const calculateAge = (value: string): number => {
  const date = importDate(chrono.en.GB.parseDate(value) as Date)
  const diffYears = Math.floor(now().endOf('day').diff(date, 'years').years)

  return diffYears
}

import TPLogo from '/public/images/TP-Logo.svg?svgr'
import FiveStarsIcon from '/public/images/tp_rating_5-star.svg?svgr'
import { TrustData } from './trustpilot-box'
import * as UI from '@/ui'

export const TPMini = ({ trustData }: { trustData: TrustData }) => {
  return (
    <div className="mx-auto font-helvetica">
      <a target="_blank" href={trustData.url} className="w-full flex justify-center items-center">
        <UI.Block gap="small" display="flexCol">
          <span title="Trustpilot">
            <TPLogo width="140" />
            <span className="sr-only">Trustpilot Logo</span>
          </span>

          <span title={`${trustData.value} out of five star rating on Trustpilot`}>
            <FiveStarsIcon width="236" height="40" className="-ml-2.5" />
            <span className="sr-only">Five Star Review</span>
          </span>

          <div className="flex text-sm font-helvetica ml-0.5">
            <div className="flex">
              <span className="font-normal mr-1">TrustScore</span>
              <span className="font-semibold">{trustData.value.toLocaleString()}</span>
            </div>

            <span className="mx-1.5">|</span>

            <div className="flex">
              <span className="font-semibold mr-1">{trustData.count.toLocaleString()}</span>
              <span className="font-normal">reviews</span>
            </div>
          </div>
        </UI.Block>
      </a>
    </div>
  )
}

export default TPMini

import { Formik, Form } from 'formik'

import { FormikConfig, FormikValues } from 'formik/dist/types'
import { type FormPropsCommon } from './index'

export type FormikFormProps<Value> = FormikConfig<Value> & FormPropsCommon

// eslint-disable-next-line @typescript-eslint/ban-types
export const FormikForm = function <Value extends FormikValues = FormikValues, ExtraProps = {}>({
  children,
  ...props
}: FormikFormProps<Value> & ExtraProps) {
  return (
    <Formik<Value> {...props}>
      {(props) => <Form>{typeof children === 'function' ? children(props) : children}</Form>}
    </Formik>
  )
}

FormikForm.displayName = 'Form.Form.FormikForm'

export default FormikForm

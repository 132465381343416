import { type FormPropsCommon } from './index'

export type HtmlFormProps = {
  children: React.ReactNode
} & FormPropsCommon

export const HtmlForm = ({ children, ...props }: HtmlFormProps) => <form {...props}>{children}</form>

HtmlForm.displayName = 'Form.Form.HtmlForm'

export default HtmlForm

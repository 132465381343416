import { useUi, type Breakpoints } from './use-ui'

export type GridSpanStyles = ''
export type GridSpanAlign = 'start' | 'center' | 'end'
export type GridSpanSizes = 'auto' | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

export const GridSpan = ({
  className,
  size,
  children,
  align,
}: {
  className?: string
  size?: Breakpoints<GridSpanSizes>
  align?: Breakpoints<GridSpanAlign>
  children?: React.ReactNode
}) => {
  const aligns = {
    default: '',

    start: 'justify-self-start', // Tailwind: sm:justify-self-start  md:justify-self-start  lg:justify-self-start  xl:justify-self-start
    center: 'justify-self-center', // Tailwind: sm:justify-self-center  md:justify-self-center  lg:justify-self-center  xl:justify-self-center
    end: 'justify-self-end', // Tailwind: sm:justify-self-end  md:justify-self-end  lg:justify-self-end  xl:justify-self-end
  }

  const sizes = {
    default: '',

    auto: 'block col-auto', // Tailwind: sm:col-auto1  md:col-auto  lg:col-auto  xl:col-auto
    0: 'hidden', // Tailwind: sm:hidden md:hidden lg:hidden xl:hidden
    1: 'block col-span-1', // Tailwind: sm:col-span-1  md:col-span-1  lg:col-span-1  xl:col-span-1
    2: 'block col-span-2', // Tailwind: sm:col-span-2  md:col-span-2  lg:col-span-2  xl:col-span-2
    3: 'block col-span-3', // Tailwind: sm:col-span-3  md:col-span-3  lg:col-span-3  xl:col-span-3
    4: 'block col-span-4', // Tailwind: sm:col-span-4  md:col-span-4  lg:col-span-4  xl:col-span-4
    5: 'block col-span-5', // Tailwind: sm:col-span-5  md:col-span-5  lg:col-span-5  xl:col-span-5
    6: 'block col-span-6', // Tailwind: sm:col-span-6  md:col-span-6  lg:col-span-6  xl:col-span-6
    7: 'block col-span-7', // Tailwind: sm:col-span-7  md:col-span-7  lg:col-span-7  xl:col-span-7
    8: 'block col-span-8', // Tailwind: sm:col-span-8  md:col-span-8  lg:col-span-8  xl:col-span-8
    9: 'block col-span-9', // Tailwind: sm:col-span-9  md:col-span-9  lg:col-span-9  xl:col-span-9
    10: 'block col-span-10', // Tailwind: sm:col-span-10  md:col-span-10  lg:col-span-10  xl:col-span-10
    11: 'block col-span-11', // Tailwind: sm:col-span-11  md:col-span-11  lg:col-span-11  xl:col-span-11
    12: 'block col-span-12', // Tailwind: sm:col-span-12  md:col-span-12  lg:col-span-12  xl:col-span-12
  }

  const ui = useUi({
    styles: {
      align: { options: aligns, selected: align },
      size: { options: sizes, selected: size },
    },
    name: 'GridSpan',
    className,
  })

  return <div className={ui.className}>{children}</div>
}

GridSpan.displayName = 'GridSpan'

export default GridSpan

import 'easymde/dist/easymde.min.css'
import dynamic from 'next/dynamic'
import { useState } from 'react'
import { useUi } from '../../use-ui'
import { boxSizes, type MarkdownPropsCommon } from './index'
import * as UI from '@/ui'

const SimpleMDE = dynamic(() => import('react-simplemde-editor'), { ssr: false })

export type InputMarkdownProps = {
  initialValue?: string | number | undefined
} & MarkdownPropsCommon

export const InputMarkdown = ({
  name,
  boxSize,
  errorMessage,
  placeholder,
  onChange,
  initialValue,
  ...props
}: InputMarkdownProps) => {
  const { className } = useUi({
    styles: {
      boxSize: { options: boxSizes, selected: boxSize },
    },
    name: 'Form.Markdown',
    className: `${
      errorMessage && 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 placeholder-red-300'
    }`,
  })

  const [value, setValue] = useState<InputMarkdownProps['initialValue']>(initialValue || props.value)

  return (
    <>
      <SimpleMDE
        value={`${value}`}
        onChange={(v) => {
          setValue(v)
          onChange && onChange(v)
        }}
        placeholder={placeholder}
        className={className}
        {...props}
      />
      <input type="hidden" name={name} value={value} />
      {errorMessage && <UI.Form.Error>{errorMessage}</UI.Form.Error>}
    </>
  )
}

InputMarkdown.displayName = 'Form.Markdown.InputMarkdown'

export default InputMarkdown

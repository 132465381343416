import * as UI from '@/ui'

export type PasswordProps = Omit<UI.Form.TextProps, 'field'>

export const Password = ({ children, ...props }: PasswordProps) => (
  <UI.Form.Text field="password" {...props}>
    {children}
  </UI.Form.Text>
)

export default Password
